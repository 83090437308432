// cartItems, cartItemToAdd
const addItemToWishlist = (state, action) => {
  const existingWishlistItemIndex = state.wishlist.findIndex(
      (item) => item.id === action.payload.id
  );

  if (existingWishlistItemIndex > -1) {
      const newState = [...state.wishlist];
      newState[existingWishlistItemIndex].quantity += action.payload.quantity;
      return newState;
  }
  return [...state.wishlist, action.payload];
};


const removeItemFromWishlist = (state, action) => { console.log('wishlist action.payload: ', action.payload.id);
  return state.wishlist.filter((item) => item.id !== action.payload.id);
};

export const reducer = (state, action) => {
  switch (action.type) {
      case "REHYDRATE":
          return { ...state, ...action.payload };
      case "ADD_ITEM_WISHLIST":
          return { ...state, wishlist: addItemToWishlist(state, action) };
      case "REMOVE_ITEM_WISHLIST":
          return { ...state, wishlist: removeItemFromWishlist(state, action) };
      case "SET_EDIT_OPTION":
          return { ...state, profileEditOption: action.payload };
      case "SET_PRODUCT":
          return {...state, product: action.payload};
      case "SET_POPUP_DATA":
          return {...state, popupData: action.payload};
      case "ADD_USER": 
          return {...state, user: action.payload};
      default:
          throw new Error(`Unknown action: ${action.type}`);
  }
};
