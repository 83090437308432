import { Fragment, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { otpSubmitAPI } from "../../../helpers/api.helper";
import { moveCursor } from "../../../helpers/helper";

export default function SecurePaymentOTP({
    order_id = null,
    setLoading = {},
    form = {},
    setPaymentSuccess = {},
    setPaymentFailed = {},
    setErrMsg = {},
    errorMsg = null,
    setOTPShow = {},
    otpSubmitRemaining = {},
    setotpSubmitRemaining = {},
    resetState = {},
}) {
    const history = useHistory();
    const [inputOTP, setInputOTP] = useState({
        d1: "",
        d2: "",
        d3: "",
        d4: "",
    });
    const handleOTPSubmit = () => {
        console.log("/////////////////");
        const otp = makeOtp();
        callOtpSubmitAPI(otp);
    };
    const callOtpSubmitAPI = useCallback(async (otp) => {
        setLoading(true);
        const response = await otpSubmitAPI(
            order_id,
            form.phone.value,
            form.email.value,
            otp
        );
        setLoading(false);
        setErrMsg(null);
        setInputOTP({
            d1: "",
            d2: "",
            d3: "",
            d4: "",
        });
        console.log("otp submit response: ", response);
        if (response.status_code == 200) {
            setPaymentSuccess(true);
            setOTPShow(false);
        } else if (response.error || response.status_code == 52035) {
            setPaymentFailed(true);
            setOTPShow(false);
            setErrMsg(response.message);
        } else if (response.status_code == 52038) {
            const remaining = response.retry_remaining;
            setotpSubmitRemaining(remaining);
            console.log("remaining: ", otpSubmitRemaining);
            if (remaining == 0) {
                setTimeout(() => {
                    resetState();
                }, 5000);
            }
            setErrMsg(response.message);
            console.log("error message: ", errorMsg);
        } else {
            setErrMsg(response.message);
            console.log("error message: ", errorMsg);
        }
    });
    const makeOtp = () => {
        console.log(
            "otp: ",
            inputOTP.d1 + inputOTP.d2 + inputOTP.d3 + inputOTP.d4
        );
        return inputOTP.d1 + inputOTP.d2 + inputOTP.d3 + inputOTP.d4;
    };
    console.log("error: ", errorMsg);

    return (
        <div className='secure-payment payment-otp d-block'>
            <div className='pertner-logo px-3 pt-3'>
                <div className='d-flex justify-content-between align-items-center'>
                    <img src='assets/images/gp.png' alt='gp' />
                    <img
                        src='assets/images/footer-logo.png'
                        alt='footer'
                        width={80}
                    />
                </div>
            </div>
            <div className='px-4 py-3'>
                {(otpSubmitRemaining > 0 || otpSubmitRemaining == null) && (
                    <Fragment>
                        <div className='pay-head text-center'>
                            <h3>Input OTP</h3>
                        </div>
                        <div className='pay-body'>
                            <div className='text-center'>
                                <p>
                                    Please fill in the OTP sent to your mobile
                                    number
                                </p>
                            </div>
                            <div className='row align-items-center row-cols-4'>
                                <div className='col px-2'>
                                    <input
                                        id='gp-otp-digit-1'
                                        type='number'
                                        className='form-control'
                                        onChange={(e) =>
                                            setInputOTP({
                                                ...inputOTP,
                                                d1: e.target.value,
                                            })
                                        }
                                        maxLength={1}
                                        onKeyUp={(e) =>
                                            moveCursor(
                                                e,
                                                null,
                                                "gp-otp-digit-1",
                                                "gp-otp-digit-2"
                                            )
                                        }
                                    />
                                </div>
                                <div className='col px-2'>
                                    <input
                                        id='gp-otp-digit-2'
                                        type='number'
                                        className='form-control'
                                        onChange={(e) =>
                                            setInputOTP({
                                                ...inputOTP,
                                                d2: e.target.value,
                                            })
                                        }
                                        maxLength={1}
                                        onKeyUp={(e) =>
                                            moveCursor(
                                                e,
                                                "gp-otp-digit-1",
                                                "gp-otp-digit-2",
                                                "gp-otp-digit-3"
                                            )
                                        }
                                    />
                                </div>
                                <div className='col px-2'>
                                    <input
                                        id='gp-otp-digit-3'
                                        type='number'
                                        className='form-control'
                                        onChange={(e) =>
                                            setInputOTP({
                                                ...inputOTP,
                                                d3: e.target.value,
                                            })
                                        }
                                        maxLength={1}
                                        onKeyUp={(e) =>
                                            moveCursor(
                                                e,
                                                "gp-otp-digit-2",
                                                "gp-otp-digit-3",
                                                "gp-otp-digit-4"
                                            )
                                        }
                                    />
                                </div>
                                <div className='col px-2'>
                                    <input
                                        id='gp-otp-digit-4'
                                        type='number'
                                        className='form-control'
                                        onChange={(e) =>
                                            setInputOTP({
                                                ...inputOTP,
                                                d4: e.target.value,
                                            })
                                        }
                                        maxLength={1}
                                        onKeyUp={(e) =>
                                            moveCursor(
                                                e,
                                                "gp-otp-digit-3",
                                                "gp-otp-digit-4",
                                                null
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}
                {errorMsg && otpSubmitRemaining > 0 && (
                    <div
                        className='text-center'
                        style={{ textAlign: "center", color: "red" }}
                    >
                        {errorMsg}
                    </div>
                )}
                {otpSubmitRemaining > 0 && (
                    <div
                        className='payment-options d-block text-center'
                        style={{ color: "#d3aa87" }}
                    >{`OTP submission attempts remainin(${otpSubmitRemaining} times..)`}</div>
                )}
                {otpSubmitRemaining == 0 && (
                    <div
                        className='text-center'
                        style={{ textAlign: "center", color: "red" }}
                    >
                        You have acceeded OTP submission limit. Please try again
                        later
                    </div>
                )}
                {(otpSubmitRemaining > 0 || otpSubmitRemaining == null) && (
                    <div className='action text-center pt-4  d-lg-block'>
                        <button
                            className='btn btn-dark mt-0'
                            onClick={handleOTPSubmit}
                        >
                            Buy
                        </button>
                    </div>
                )}
                {/* <div className="resend text-center mt-3">
                    <p>Resend (58s)</p>
                </div> */}
            </div>
        </div>
    );
}
