export default function SecurePaymentFailed({
    selectedChanel = {},
    message = "",
}) {
    const status_code = new URLSearchParams(window.location.search).get(
        "status_code"
    );

    return (
        <div className='secure-payment failed d-block'>
            <div className='pertner-logo px-3 pt-3'>
                <div className='d-flex justify-content-between align-items-center'>
                    <img
                        src={selectedChanel.thumbnail_url}
                        alt={selectedChanel.name}
                    />
                    <img
                        src='/assets/images/footer-logo.png'
                        alt=''
                        width={80}
                    />
                </div>
            </div>
            <div className='pay-body text-center'>
                <img
                    src={
                        status_code == 403
                            ? "/assets/images/info-256.png"
                            : "/assets/images/cross.png"
                    }
                    alt=''
                    height={status_code == 403 ? 88 : "auto"}
                    width={status_code == 403 ? 88 : "auto"}
                />
                <h2 className='text-danger'>
                    {status_code == 500
                        ? "Payment is Failed!"
                        : status_code == 403
                        ? ""
                        : "Request Failed!"}
                </h2>
                <p>{message}</p>
            </div>
        </div>
    );
}
