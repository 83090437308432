import { useEffect } from "react";
import Footer from "../components/layout/footer/footer";
import Header from "../components/layout/header/header";

export default function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);

        // loading bootstrap plugin
        const script2 = document.createElement("script");
        script2.src = "/assets/js/bootstrap.bundle.min.js";
        script2.async = true;
        document.body.appendChild(script2);

        // loading slick plugin
        const script3 = document.createElement("script");
        script3.src = "/assets/vendor/slick/slick.min.js";
        script3.async = true;
        document.body.appendChild(script3);

        //loading aos plugin
        const script4 = document.createElement("script");
        script4.src = "/assets/vendor/aos/aos.js";
        script4.async = true;
        document.body.appendChild(script4);

        // loading main js file
        const script1 = document.createElement("script");
        script1.src = "/assets/js/main.js";
        script1.async = true;
        document.body.appendChild(script1);

        return () => {
            document.body.removeChild(script1);
            document.body.removeChild(script2);
            document.body.removeChild(script3);
            document.body.removeChild(script4);
        };
    }, []);
    return (
        <div className='main-wrapper'>
            <Header />
            <main className='main-area'>
                <section className=''>
                    <div className='container'>
                        <div className='contact py-5'>
                            <div className='text-center'>
                                <strong>Privacy Policy</strong>
                            </div>
                            <div>
                                <p>
                                    This service is provided to you by Momagic
                                    Bangladesh a limited liability company duly
                                    incorporated and registered under the laws
                                    of Bangladesh (Referred to as Momagic)
                                    is/are concerned about the privacy of the
                                    data and information of users (including
                                    sellers and buyers/customers whether
                                    registered or non-registered) accessing,
                                    offering, selling or purchasing products or
                                    services on Momagic’s websites, mobile sites
                                    or mobile applications ("Mobile
                                    app/Website") on the Website and otherwise
                                    doing business with Momagic.
                                </p>
                                <p>
                                    The terms "We" / "Us" / "Our" individually
                                    and collectively refer to each entity being
                                    part of the definition of Momagic and the
                                    terms "You" /"Your" / "Yourself" refer to
                                    the users. This Privacy Policy is a contract
                                    between You and the respective Momagic
                                    entity whose Website You use or access or
                                    You otherwise deal with. This Privacy Policy
                                    shall be read together with the respective
                                    Terms Of Use or other terms and condition of
                                    the respective Momagic entity and its
                                    respective Website or nature of business of
                                    the Website.
                                </p>
                                <p>
                                    Our privacy policy is subject to change at
                                    any time without notice. To make sure you
                                    are aware of any changes, please review this
                                    policy periodically.
                                </p>
                                <p>
                                    By visiting this Website you agree to be
                                    bound by the terms and conditions of this
                                    Privacy Policy. If you do not agree please
                                    do not use or access our Website.
                                </p>
                                <p>
                                    By mere use of the Website., you expressly
                                    consent to our use and disclosure of your
                                    personal information in accordance with this
                                    Privacy Policy. This Privacy Policy is
                                    incorporated into and subject to the Terms
                                    of Use.
                                </p>
                                <p>Table of Contents: </p>
                                <ul>
                                    <li>
                                        <a href='#content-1'>
                                            Collection of Personally
                                            Identifiable Information and other
                                            Information
                                        </a>
                                    </li>
                                    <li>
                                        <a href='#content-2'>
                                            Use of Demographic / Profile Data /
                                            Your Information
                                        </a>
                                    </li>
                                    <li>
                                        <a href='#content-3'>Cookies</a>
                                    </li>
                                    <li>
                                        <a href='#content-4'>
                                            Sharing of personal information
                                        </a>
                                    </li>
                                    <li>
                                        <a href='#content-5'>
                                            Linking to other Mobile app/Websites
                                        </a>
                                    </li>
                                    <li>
                                        <a href='#content-6'>
                                            Security Precautions
                                        </a>
                                    </li>
                                    <li>
                                        <a href='#content-7'>Advertisements</a>
                                    </li>
                                    <li>
                                        <a href='#content-8'>Consent</a>
                                    </li>
                                    <li>
                                        <a href='#content-9'>Support</a>
                                    </li>
                                </ul>
                            </div>

                            <div id='content-1'>
                                <strong>
                                    1. Collection of Personally Identifiable
                                    Information and other Information
                                </strong>
                                <p>
                                    When you use our Website., we collect and
                                    store your personal information which is
                                    provided by you from time to time. Our
                                    primary goal in doing so is to provide you a
                                    safe, efficient, smooth and customized
                                    experience. This allows us to provide
                                    services and features that most likely meet
                                    your needs, and to customize our Website to
                                    make your experience safer and easier.
                                </p>
                                <p>
                                    More importantly, while doing so we collect
                                    personal information from you that we
                                    consider necessary for achieving this
                                    purpose.
                                </p>
                                <p>
                                    In general, you can browse the Website
                                    without telling us who you are or revealing
                                    any personal information about yourself.
                                    Once you give us your personal information,
                                    you are not anonymous to us. Where possible,
                                    we indicate which fields are required and
                                    which fields are optional. You always have
                                    the option to not provide information by
                                    choosing not to use a particular service or
                                    feature on the Website. We may automatically
                                    track certain information about you based
                                    upon your behavior on our Website. We use
                                    this information to do internal research on
                                    our users' demographics, interests, and
                                    behavior to better understand, protect and
                                    serve our users. This information is
                                    compiled and analyzed on an aggregated
                                    basis. This information may include the URL
                                    that you just came from (whether this URL is
                                    on our Website or not), which URL you next
                                    go to (whether this URL is on our Website or
                                    not), your computer browser information, and
                                    your IP address.
                                </p>
                                <p>
                                    If you choose to buy on the Website, we
                                    collect information about your buying
                                    behavior. If you transact with us, we
                                    collect some additional information, such as
                                    a billing address, a credit/ debit card
                                    number and a credit / debit card expiration
                                    date and/ or other payment instrument
                                    details and tracking information from
                                    cheques or money orders.
                                </p>
                                <p>
                                    If you send us personal correspondence, such
                                    as emails or letters, or if other users or
                                    third parties send us correspondence about
                                    your activities or postings on the Website,
                                    we may collect such information into a file
                                    specific to you.
                                </p>
                                <p>
                                    We collect personally identifiable
                                    information (email address, name, phone
                                    number, credit card / debit card / other
                                    payment instrument details, etc.) from you
                                    when you set up an account with us. While
                                    you can browse some sections of our Website
                                    without being a registered member, certain
                                    activities (such as placing an order) do
                                    require registration. We do use your contact
                                    information to send you offers based on your
                                    previous orders and your interests.
                                </p>
                            </div>
                            <div id='content-2'>
                                <strong>
                                    2. Use of Demographic / Profile Data / Your
                                    Information
                                </strong>
                                <p>
                                    We use personal information to provide the
                                    services you request. To the extent we use
                                    your personal information to market to you,
                                    we will provide you the ability to opt-out
                                    of such uses. We use your personal
                                    information to resolve disputes;
                                    troubleshoot problems; help promote a safe
                                    service; collect money; measure consumer
                                    interest in our products and services,
                                    inform you about online and offline offers,
                                    products, services, and updates; customize
                                    your experience; detect and protect us
                                    against error, fraud and other criminal
                                    activity; enforce our terms and conditions;
                                    and as otherwise described to you at the
                                    time of collection.In our efforts to
                                    continually improve our product and service
                                    offerings, we collect and analyze
                                    demographic and profile data about our
                                    users' activity on our Website. We identify
                                    and use your IP address to help diagnose
                                    problems with our server, and to administer
                                    our Website. Your IP address is also used to
                                    help identify you and to gather broad
                                    demographic information.
                                </p>
                            </div>
                            <div id='content-3'>
                                <strong>3. Cookies</strong>
                                <p>
                                    A "cookie" is a small piece of information
                                    stored by a web server on a web browser so
                                    it can be later read back from that browser.
                                    Cookies are useful for enabling the browser
                                    to remember information specific to a given
                                    user. We place both permanent and temporary
                                    cookies in your computer's hard drive. The
                                    cookies do not contain any of your
                                    personally identifiable information.
                                </p>
                            </div>
                            <div id='content-4'>
                                <strong>
                                    4. Sharing of personal information
                                </strong>
                                <p>
                                    User/you will endeavor to deploy basic
                                    security software, patches to maintain the
                                    hygiene of the devices. Moreover, you at all
                                    times during usage of our service keep the
                                    device free from all viruses, Trojan horse,
                                    spam, malware etc. which might affect our
                                    service to you. Momagic may employ
                                    Reasonable Security Practices such as
                                    procedural and technological security
                                    measures, consistent with the relevant
                                    industry practice. Such measures are
                                    reasonably designed to help protect the
                                    Personal Information from loss, unauthorized
                                    access, disclosure, alteration or
                                    destruction. Momagic may also use
                                    encryption, secure socket layer, firewalls,
                                    internal restriction, password protection
                                    and other security measures to help prevent
                                    unauthorized access to the Personal
                                    Information.
                                </p>
                                <p>
                                    Momagic will endeavor to keep the Personal
                                    Information safe and secure and to prevent
                                    any unauthorized access or misuse of the
                                    same. You/user/ agrees that Momagic shall
                                    not be liable for any loss, damage (whether
                                    direct, indirect, consequential or
                                    incidental) or harm caused to You due to the
                                    unauthorized access or misuse of the
                                    Personal Information by any third party
                                    (beyond the control of Momagic).
                                </p>
                                <strong>How We Use Information</strong>
                                <p>
                                    We use information collected from you to
                                    provide, administer, and facilitate your use
                                    of our Services, including to display
                                    customized content; process or fulfill your
                                    request(s) and/or order(s) for products,
                                    services, information or features, including
                                    the use of specific functionalities of our
                                    products and/or services. Solicit feedback
                                    from users; As required or permitted by law;
                                    As we, in our sole discretion, otherwise
                                    determine to be necessary or required to
                                    ensure the safety and/or integrity of our
                                    users, employees, third parties, members of
                                    the public, and/or our Services; Identify
                                    unique users across devices; or Tailor
                                    advertisements across devices.
                                </p>
                                <p>
                                    We may de-identify and aggregate information
                                    collected through the Service and use it for
                                    any lawful purpose.
                                </p>
                            </div>
                            <div id='content-5'>
                                <strong>
                                    5. Linking to other Mobile app/Websites
                                </strong>
                                <p>
                                    hullor.io has multiple service & payment
                                    channel partners. A user maybe directed to
                                    one of their sites to execute successful
                                    payment procedure. These sites are
                                    completely trusted and officially endorsed
                                    by us. The sites are as follows:
                                    Grameenphone DOB payment, Nagad, SSLCommerz
                                    payment gateway, Truecaller etc Momagic is
                                    not responsible for the privacy practices or
                                    the content of any other mobile app/websites
                                    for which links may be provided on the
                                    Website or which provides links to the
                                    Website. By clicking on a link, logo or
                                    other such items, or using certain services
                                    available on the Website, the User may no
                                    longer be on the Website and in such
                                    circumstances the privacy policy (if any) of
                                    such other mobile app/website shall apply.
                                    Momagic encourages Users to read the privacy
                                    policy/ statement of such other mobile
                                    app/websites.
                                </p>
                            </div>
                            <div id='content-6'>
                                <strong>6. Security Precautions</strong>
                                <p>
                                    Our Website has reasonable security measures
                                    in place to protect the loss, misuse, and
                                    alteration of the information under our
                                    control. Whenever you change or access your
                                    account information, we offer the use of a
                                    secure server. Once your information is in
                                    our possession we adhere to reasonable
                                    security guidelines, protecting it against
                                    unauthorized access.
                                </p>
                            </div>
                            <div id='content-7'>
                                <strong>7. Advertisements</strong>
                                <p>
                                    We use third-party advertising companies to
                                    serve ads when you visit our Website. These
                                    companies may use information (not including
                                    your name, address, email address, or
                                    telephone number) about your visits to this
                                    and other Website in order to provide
                                    advertisements about goods and services of
                                    interest to you.
                                </p>
                            </div>
                            <div id='content-8'>
                                <strong>8. Consent</strong>
                                <p>
                                    By using the Website and/ or by providing
                                    your information, you consent to the
                                    collection and use of the information you
                                    disclose on the Website in accordance with
                                    this Privacy Policy, including but not
                                    limited to Your consent for sharing your
                                    information as per this privacy policy.
                                </p>
                                <p>
                                    If we decide to change our privacy policy,
                                    we will post those changes on this page so
                                    that you are always aware of what
                                    information we collect, how we use it, and
                                    under what circumstances we disclose it.
                                </p>
                            </div>
                            <div id='content-9'>
                                <strong>9. Support</strong>
                                <p>
                                    To find more information about our service
                                    and its features or if you need assistance
                                    with your account, please share your details
                                    in contact us section or write{" "}
                                    <b>Email : supports@momagicbd.com</b>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}
