"use strict";

const tester =
    /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

export let validateEmail = function (email = "") {
    if (!email) return false;

    var emailParts = email.split("@");

    if (emailParts.length !== 2) return false;

    var account = emailParts[0];
    var address = emailParts[1];

    if (account.length > 64) return false;
    else if (address.length > 255) return false;

    var domainParts = address.split(".");
    if (
        domainParts.some(function (part) {
            return part.length > 63;
        })
    )
        return false;

    if (!tester.test(email)) return false;

    return true;
};

export let validatePhone = (phone = "") => {
    if (!phone) return false;
    const regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
    if (phone.length != 11 && phone.length != 13) return false;
    return !(!phone || regex.test(phone) === false);
};

export const isValidGPMSISDN = (msisdn) => {
    if (typeof msisdn !== "string") return false;

    const regex = /^(88017\d{8}|88013\d{8})$/;
    return regex.test(msisdn);
};

export let makeGenre = (products = []) => {
    let genres = [];
    Object.entries(products).map(([key, value]) => {
        genres.push({ title: key, slug: value[0].genre_slug, items: value });
    });
    return genres;
};

export let findHighligtedProduct = (products = []) => {
    for (let i = 0; i < products.length; i++) {
        if (products[i].highlight == true) return products[i];
    }

    return products[0];
};

export const moveCursor = (event, prev, cur, next) => {
    const length = document.getElementById(cur).value.length;
    // go to next field
    if (length && next != null) {
        document.getElementById(next).focus();
    }
    // go back to previous field
    if (event.key == "Backspace" && prev != null) {
        document.getElementById(prev).focus();
    }
};

export const moveKeyToFirst = (obj, keyToMove) => {
    if (obj.hasOwnProperty(keyToMove)) {
        const orderedObj = { [keyToMove]: obj[keyToMove] };
        for (const key in obj) {
            if (key !== keyToMove) {
                orderedObj[key] = obj[key];
            }
        }
        return orderedObj;
    }
    return obj;
};

export const getTruecallerPackageId = (pckg) => {
    switch (pckg) {
        case "yearly":
            return 41;
        case "half-yearly":
            return 40;
        case "monthly":
            return 39;
        case "weekly":
            return 38;

        default:
            return 37;
    }
};

// export default {
//   validateEmail,
//   validatePhone,
//   makeGenre,
//   findHighligtedProduct
// }
