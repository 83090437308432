import React, { useReducer, useContext, createContext } from 'react';
import { reducer } from './profile.reducer';
import { useStorage } from '../../utils/use-storage';
const ProfileContext = createContext({} as any);
const INITIAL_STATE = {
  isAuthenticated: false,
  profileEditOption: null,
  wishlist: [],
  product: null,
  popupData: null,
  access_token: null,
  user: null
};

const useProfileActions = (initialProfile = INITIAL_STATE) => {
  const [state, dispatch] = useReducer(reducer, initialProfile);

  const addItemWishlistHandler = (item, quantity = 1) => {
    dispatch({ type: 'ADD_ITEM_WISHLIST', payload: { ...item, quantity } });
  };
  const setUser = (user) => {
    dispatch({type: 'ADD_USER', payload: user});
  };

  const removeItemWishlistHandler = (item, quantity = 1) => {
    dispatch({ type: 'REMOVE_ITEM_WISHLIST', payload: { ...item, quantity } });
  };
  const rehydrateLocalState = (payload) => {
    dispatch({ type: 'REHYDRATE', payload });
  };
  const isInWishlistHandler = (id) => {
    console.log('is in wishlist: ', id, state.wishlist?.some((item) => item.id === id));
    return state.wishlist?.some((item) => item.id === id);
  };
  const editOptionHandler = (value) => {
    dispatch({ type: 'SET_EDIT_OPTION', payload: value });
  };
  const getItemsCount = () => {
    return state.wishlist.length;
  };
  const setProductHandler = (product) => {
    dispatch({ type: 'SET_PRODUCT', payload: product });
  };
  const setPopUpHandler = (data) => {
    dispatch({ type: 'SET_POPUP_DATA', payload: data });
  };
  return {
    state,
    getItemsCount,
    rehydrateLocalState,
    addItemWishlistHandler,
    removeItemWishlistHandler,
    isInWishlistHandler,
    editOptionHandler,
    setProductHandler,
    setPopUpHandler,
    setUser,
  };
};

export const ProfileProvider = ({ children }) => {
  const {
    state,
    rehydrateLocalState,
    getItemsCount,
    addItemWishlistHandler,
    removeItemWishlistHandler,
    isInWishlistHandler,
    editOptionHandler,
    setProductHandler,
    setPopUpHandler,
    setUser,
  } = useProfileActions();
  const { rehydrated, error } = useStorage(state, rehydrateLocalState);

  return (
    <ProfileContext.Provider
      value={{
        access_token: state.access_token,
        wishlist: state.wishlist,
        product: state.product,
        profileEditOption: state.profileEditOption,
        isInWishlist: isInWishlistHandler,
        addItemToWishlist: addItemWishlistHandler,
        removeItemFromWishlist: removeItemWishlistHandler,
        setEditOption: editOptionHandler,
        setProduct: setProductHandler,
        popupData: state.popupData,
        setPopupData: setPopUpHandler,
        addUser: setUser,
        user: state.user
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => useContext(ProfileContext);

export default ProfileProvider;
