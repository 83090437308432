import axios from "axios";
import { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { getAccessToken } from "./helpers/api.helper";
import InvalidURL from "./pages/404";
import PrivateRoute from "./routes/PrivateRoute";
import { privateRoutes } from "./routes/private.routes";
import { publicRoutes } from "./routes/public.routes";

import "./assets/css/style.css";

function App() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        callAPI();
    }, []);

    const callAPI = async () => {
        const res = await getAccessToken();

        if (res?.success && res?.access_token) {
            setLoading(false);
            axios.defaults.headers.common[
                "authorization"
            ] = `Bearer ${res?.access_token}`;
        }
    };

    return (
        <>
            {loading ? (
                <div className='loading'>
                    <div className='lds-facebook'>
                        <div />
                        <div />
                        <div />
                    </div>
                </div>
            ) : (
                <div className='App'>
                    <Switch>
                        {publicRoutes.map((route, indx) => (
                            <Route
                                exact
                                path={route.path}
                                component={() => <route.component />}
                                key={indx}
                            />
                        ))}

                        {privateRoutes.map((route, indx) => (
                            <PrivateRoute
                                exact
                                path={route.path}
                                component={() => <route.component />}
                                key={indx}
                            />
                        ))}

                        <Route path='*' component={() => <InvalidURL />} />
                    </Switch>
                    <ToastContainer
                        position='bottom-right'
                        // autoClose={5000}
                        // hideProgressBar={false}
                        // newestOnTop={false}
                        closeOnClick
                        // rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme='colored'
                    />
                </div>
            )}
        </>
    );
}

export default App;
