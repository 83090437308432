import { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/layout/footer/footer";
import Header from "../components/layout/header/header";


export default function InvalidURL() {
    useEffect(() => {
        window.scrollTo(0, 0);
        // loading jquery file
        const script5 = document.createElement('script');
        script5.src = "/assets/js/jquery.min.js";
        script5.async = true;
        document.body.appendChild(script5);

        // loading bootstrap plugin
        const script2 = document.createElement('script');
        script2.src="/assets/js/bootstrap.bundle.min.js";
        script2.async = true;
        document.body.appendChild(script2);

        // loading slick plugin 
        const script3 = document.createElement('script');
        script3.src = "/assets/vendor/slick/slick.min.js";
        script3.async = true;
        document.body.appendChild(script3);

        //loading aos plugin
        const script4 = document.createElement('script');
        script4.src = "/assets/vendor/aos/aos.js";
        script4.async = true;
        document.body.appendChild(script4);

        // loading main js file
        const script1 = document.createElement('script');
        script1.src = "/assets/js/main.js";
        script1.async = true;
        document.body.appendChild(script1);


        return () => {
            document.body.removeChild(script5);
            document.body.removeChild(script2);
            document.body.removeChild(script3);
            document.body.removeChild(script4);
            document.body.removeChild(script1);
            
        };
    }, []);

    return (
        <Fragment>
            <Header />
            <section className="page_404">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 ">
                            <div className="col-sm-10 col-sm-offset-1  text-center">
                                <div className="four_zero_four_bg">
                                    <h1 className="text-center ">404</h1>
                                </div>

                                <div className="contant_box_404">
                                    <h3 className="h2">
                                        Look like you're lost
                                    </h3>

                                    <p>the page you are looking for not avaible!</p>

                                    <Link to="/" className="link_404">Go to Home</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Fragment>
    );
};