import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BASE_URL } from "../../../config";
import { BL_SDP_CONSENT } from "../../../constants/api.constants";
import { dob_services_configurations } from "../../../constants/truecaller.constants";
import { moveCursor } from "../../../helpers/helper";
import {
    banglalinkCheckPaymentStatus,
    decryptObject,
} from "../../../utils/helper";
import LoaderConsent from "./Loader";
import "./style.css";

export default function BanglalinkDobConsent() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get("q");
    const {
        keyword,
        charge_code: chargeCode,
        reqId,
        urls,
        msisdn,
        product: product_id,
    } = decryptObject(query, "BL_SDP");

    const [isSubmitDisabled, setSubmitDisabled] = useState(true);
    const [remainingTime, setRemainingTime] = useState(240);
    const [loading, setLoading] = useState(false);

    const product =
        dob_services_configurations.filter(
            (e) => e.id === Number(product_id)
        )[0] || {};

    const [otp, setOtp] = useState({
        d1: "",
        d2: "",
        d3: "",
        d4: "",
        d5: "",
    });

    const handleSubmit = async () => {
        setLoading(true);
        const { data: res } = await axios.get(
            `${BL_SDP_CONSENT}?planId=9913110002&chargeCode=${chargeCode}&&featureId=CONSENT&requestId=${reqId}&consentNo=${
                otp.d1 + otp.d2 + otp.d3 + otp.d4 + otp.d5
            }&msisdn=${msisdn}`
        );
        if (Number(res?.data?.responseCode) === 0) {
            // window.location.href = urls?.success;
            const api_url = [37, 38, 39].includes(product?.id)
                ? `${BASE_URL}/api/v2/bldob/check-payment-status-truecaller/${reqId}`
                : `${BASE_URL}/api/v2/bldob/check-payment-status/${reqId}`;

            const res2 = await banglalinkCheckPaymentStatus(api_url);
            if (res2?.result === 0) {
                window.location.href = urls?.success;
            } else {
                window.location.href = urls?.error;
            }
        } else {
            window.location.href = urls?.error;
        }
    };

    useEffect(() => {
        setTimeout(() => {
            setSubmitDisabled(true);
        }, 4 * 60 * 1000);
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            // Decrement the remaining time by 1 second
            setRemainingTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
        }, 1000); // Update every second

        // Clean up the interval when the component is unmounted
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (
            otp.d1.length > 0 &&
            otp.d2.length > 0 &&
            otp.d3.length > 0 &&
            otp.d4.length > 0 &&
            otp.d5.length > 0
        ) {
            setSubmitDisabled(false);
        } else {
            setSubmitDisabled(true);
        }
    }, [otp]);

    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;

    return (
        <div className=''>
            {loading ? <LoaderConsent /> : ""}
            <div className='main-wrapper-2 text-center'>
                <div className='logo text-center'>
                    <a href='#'>
                        <img src={product?.logo} alt='cp-logo' />
                    </a>
                </div>
                <div className='banner'>
                    <img src={product?.banner} alt='cp-banner' />
                </div>
                <div className='content'>
                    <h2 className='title'>Verification code</h2>
                    <p style={{ lineHeight: 1.8 }}>
                        We have sent one time password to the mobile number{" "}
                        <span className='mobile-number'>{msisdn}</span>
                    </p>
                    <h3 className='price'>
                        <span>{`TK ${Number(product?.amount)}/${
                            product?.validity_in_days === 30
                                ? "month"
                                : product?.validity_in_days === 7
                                ? "week"
                                : "day"
                        }`}</span>{" "}
                        excluding tax
                    </h3>
                    <p className='package'>
                        For <span>{product?.description}</span>
                    </p>
                    <form className='form-inner'>
                        <div className='otp-form'>
                            <input
                                type='number'
                                className='input-field'
                                id='o1'
                                value={otp.d1}
                                onChange={(e) =>
                                    setOtp({ ...otp, d1: e.target.value })
                                }
                                maxLength={1}
                                onKeyUp={(e) => moveCursor(e, null, "o1", "o2")}
                            />
                            <input
                                type='number'
                                className='input-field'
                                id='o2'
                                value={otp.d2}
                                onChange={(e) =>
                                    setOtp({ ...otp, d2: e.target.value })
                                }
                                maxLength={1}
                                onKeyUp={(e) => moveCursor(e, "o1", "o2", "03")}
                            />
                            <input
                                type='number'
                                className='input-field'
                                id='03'
                                value={otp.d3}
                                onChange={(e) =>
                                    setOtp({ ...otp, d3: e.target.value })
                                }
                                maxLength={1}
                                onKeyUp={(e) => moveCursor(e, "o2", "03", "04")}
                            />
                            <input
                                type='number'
                                className='input-field'
                                id='04'
                                value={otp.d4}
                                onChange={(e) =>
                                    setOtp({ ...otp, d4: e.target.value })
                                }
                                maxLength={1}
                                onKeyUp={(e) => moveCursor(e, "03", "04", "05")}
                            />
                            <input
                                type='number'
                                className='input-field'
                                id='05'
                                value={otp.d5}
                                onChange={(e) =>
                                    setOtp({ ...otp, d5: e.target.value })
                                }
                                maxLength={1}
                                onKeyUp={(e) => moveCursor(e, "04", "05", null)}
                            />
                        </div>
                        {/* <button type='button' className='resend-btn'>
                            Resend
                        </button> */}
                        <p
                            className='resend-btn'
                            style={{
                                cursor: "default",
                                textDecoration: "none",
                            }}
                        >{`Your OTP will expire in ${minutes} min, ${seconds} sec`}</p>
                        <button
                            type='submit'
                            className='continue-btn'
                            disabled={isSubmitDisabled}
                            onClick={(e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}
                        >
                            Continue
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}
