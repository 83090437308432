import { Fragment, useCallback, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Form, FormGroup, Input, Label } from "reactstrap";
import {
    getPopupData,
    orderSubmitAPI,
    truecallerPayment,
} from "../../helpers/api.helper";
import { validateEmail, validatePhone } from "../../helpers/helper";
import ButtonSubmit from "./payment/buttonSubmit";
import CardItem from "./payment/cardItem";
import Channels from "./payment/chanels";
import CustomerDetailsForm from "./payment/customerDetailsForm";
import Packages from "./payment/packages";
import Price from "./payment/price";
import SecurePaymentFailed from "./payment/securePaymentFailed";
import SecurePaymentInProgress from "./payment/securePaymentInProgress";
import SecurePaymentOTP from "./payment/securePaymentOTP";
import SecurePaymentSuccess from "./payment/securePaymentSuccess";

const formInit = {
    email: {
        value: null,
        isValid: true,
    },
    phone: {
        value: null,
        isValid: true,
    },
};

export default function Payment({ product = {}, setProduct = {} }) {
    //alert(123)
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const status_code = new URLSearchParams(window.location.search).get(
        "status_code"
    );

    // const { product, setProduct, popupData, setPopupData } = useProfile(); console.log('popup data payment.js: ', popupData);
    const [form, setForm] = useState(formInit);
    const [additionalData, setAdditionalData] = useState({});
    const [selectedChanel, setChanel] = useState({});
    const [loading, setLoading] = useState(false);
    const [showOTP, setOTPShow] = useState(false);
    const [otpSubmitRemaining, setotpSubmitRemaining] = useState(null);
    const [orderId, setOrderId] = useState(null);
    const [errorMsg, setErrMsg] = useState(null);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [paymentFailed, setPaymentFailed] = useState(false);
    const [products, setProducts] = useState([]);
    const [channels, setChannels] = useState([]);
    const [extraInputs, setExtraInputs] = useState(null);
    const [paymentMethodError, setPaymentMethodError] = useState(false);
    const [paymentMethodMessage, setPaymentMethodMessage] = useState("");
    const [agreed, setAgreed] = useState(false);

    const handleCheckboxChange = () => {
        setAgreed(!agreed);
    };

    const handleFormSubmit = () => {
        const isEmailValid =
            product?.category_id === 12
                ? true
                : validateEmail(form.email.value);
        const isPhoneValid = validatePhone(form.phone.value);
        setForm({
            ...form,
            email: { ...form.email, isValid: isEmailValid },
            phone: { ...form.phone, isValid: isPhoneValid },
        });
        if (isEmailValid && isPhoneValid) {
            setLoading(true);
            callPaymentAPI();
        }
    };
    // console.log("form: ", form);
    const callPaymentAPI = useCallback(async () => {
        const response =
            product?.category_id === 12
                ? await truecallerPayment(
                      product.category_id,
                      selectedChanel.payment_channel_id,
                      product.product_id,
                      form.email.value,
                      form.phone.value,
                      additionalData,
                      "hullor"
                  )
                : await orderSubmitAPI(
                      product.category_id,
                      selectedChanel.payment_channel_id,
                      product.product_id,
                      form.email.value,
                      form.phone.value,
                      additionalData
                  );

        if (response.status_code == 403) {
            history.push({
                pathname: window.location.pathname,
                search: `?status_code=${response.status_code}`,
            });
        }

        if (response.redirect && response.success) {
            window.location.href = response.charge_redirect_url;
            // history.push(response.charge_redirect_url);
        } else if (
            response.status_code === 6026 ||
            response.status_code == 52038 ||
            response.status_code == 6030 ||
            response.status_code == 6029 ||
            response.status_code == 6029 ||
            response.status_code == 200
        ) {
            setOrderId(response.order_id);
            console.log("oreder id: ", orderId);
            setOTPShow(true);
            setLoading(false);
        } else if (response.error) {
            setErrMsg(response.error);
            setLoading(false);
        } else {
            setPaymentFailed(true);
            setErrMsg(response.message);
            setLoading(false);
        }
    });

    const loadData = useCallback(async () => {
        const response = await getPopupData(product.category_id);

        if (response.success && response.status_code) {
            const { products, channels } = response;
            setProducts(products);
            setChannels(channels);
            setExtraInputs(response.extra_fields);
            // setProduct(popupData);
        }
    });

    const resetAll = () => {
        document.body.style.overflow = "";
        if (status_code == 200 || status_code == 500) history.push("/");
        setForm(formInit);
        setProduct(null);
        setChanel({});
        setLoading(false);
        setOTPShow(false);
        setOrderId(null);
        setErrMsg(null);
        setPaymentFailed(false);
        setPaymentSuccess(false);
        // setPopupData(null);
        setProduct(null);
    };
    useEffect(() => {
        document.body.style.overflow = "hidden";
        loadData();
    }, [product]);

    useEffect(() => {
        if (selectedChanel?.payment_channel_id === 6 && product?.price < 10) {
            setPaymentMethodError(true);
            setPaymentMethodMessage(
                "Please, select a product of at least 10 BDT!"
            );
        } else if (selectedChanel?.payment_channel_id === 6 && !agreed) {
            setPaymentMethodError(true);
        } else {
            setPaymentMethodError(false);
            setPaymentMethodMessage("");
        }
    }, [selectedChanel, product, agreed]);

    // useEffect(async () => {
    //     if(!responseData && product) setResponseData(await getPopupData(product.category_id));
    // }); console.log('resposeData: ', responseData);

    // if(!popupData && !product ) return <h1>loading...</h1>; console.log('product: ', product); console.log('loading: ', loading);
    // if(!products.length) return <h1>loading...</h1>;

    return (
        <Fragment>
            <div
                className={`modal fade payment-modal-wrap ${
                    (status_code == 200 || status_code == 500 || product) &&
                    "show d-block"
                }`}
                // id="staticBackdrop"
                data-bs-backdrop='static'
                data-bs-keyboard='false'
                tabIndex={-1}
                aria-labelledby='staticBackdropLabel'
                aria-hidden='true'
            >
                <div className='modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl'>
                    <div className='modal-content payments position-relative'>
                        <button
                            type='button'
                            className='btn-close position-absolute top-0 end-0 d-none d-lg-block'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                            onClick={(e) => {
                                e.preventDefault();
                                /*setProduct(null);*/ resetAll();
                            }}
                        />
                        <div className='modal-header d-block d-lg-none'>
                            <h5 className='modal-title'>
                                {product && product.product}
                            </h5>
                            <button
                                type='button'
                                className='btn-close position-absolute top-0 end-0'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                                onClick={(e) => {
                                    e.preventDefault();
                                    /*setProduct(null);*/ resetAll();
                                }}
                            />
                        </div>
                        <div className='modal-body p-5'>
                            <div className='row align-items-center'>
                                {product &&
                                    status_code != 200 &&
                                    status_code != 500 && (
                                        <CardItem product={product} />
                                    )}
                                <div className='col-lg-9'>
                                    <div className='payment-modal px-5 py-3 ms-lg-5 ms-3 rounded'>
                                        <div className='payment-options d-block'>
                                            {!loading &&
                                                !showOTP &&
                                                !paymentSuccess &&
                                                !paymentFailed &&
                                                status_code != 200 &&
                                                status_code != 500 && (
                                                    <Packages
                                                        packages={products}
                                                        product={product}
                                                        setProduct={setProduct}
                                                    />
                                                )}
                                            {!loading &&
                                                !showOTP &&
                                                !paymentSuccess &&
                                                !paymentFailed &&
                                                status_code != 200 &&
                                                status_code != 500 && (
                                                    <Channels
                                                        channels={channels}
                                                        selectedChanel={
                                                            selectedChanel
                                                        }
                                                        setChanel={setChanel}
                                                        product={product}
                                                    />
                                                )}
                                            {!loading &&
                                                !showOTP &&
                                                !paymentSuccess &&
                                                !paymentFailed &&
                                                status_code != 200 &&
                                                status_code != 500 && (
                                                    <Price
                                                        price={
                                                            product &&
                                                            product.price
                                                        }
                                                    />
                                                )}
                                            {!loading &&
                                                !showOTP &&
                                                !paymentFailed &&
                                                !paymentSuccess &&
                                                status_code != 200 &&
                                                status_code != 500 && (
                                                    <CustomerDetailsForm
                                                        form={form}
                                                        product={product}
                                                        selectedChanel={
                                                            selectedChanel
                                                        }
                                                        setForm={setForm}
                                                        extraFields={
                                                            extraInputs
                                                        }
                                                        additionalData={
                                                            additionalData
                                                        }
                                                        setAdditionalData={
                                                            setAdditionalData
                                                        }
                                                    />
                                                )}
                                            {paymentMethodMessage && (
                                                <div
                                                    className='d-flex align-items-center'
                                                    style={{
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <div className='row gx-5 row-cols-1 row-cols-lg-1'>
                                                        <div className='col'>
                                                            <p
                                                                className='text-danger'
                                                                style={{
                                                                    // color: "red",
                                                                    marginBottom:
                                                                        "0px",
                                                                }}
                                                            >
                                                                {
                                                                    paymentMethodMessage
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {!loading &&
                                                !showOTP &&
                                                !paymentFailed &&
                                                !paymentSuccess &&
                                                status_code != 200 &&
                                                status_code != 500 &&
                                                selectedChanel?.payment_channel_id ===
                                                    6 && (
                                                    <div
                                                        className='d-flex align-items-center'
                                                        style={{
                                                            flexDirection:
                                                                "column",
                                                        }}
                                                    >
                                                        <div className='row gx-5 row-cols-1 row-cols-lg-1'>
                                                            <div className='col'>
                                                                <Form className='mt-2'>
                                                                    <FormGroup
                                                                        check
                                                                        inline
                                                                    >
                                                                        <Input
                                                                            type='checkbox'
                                                                            checked={
                                                                                agreed
                                                                            }
                                                                            onChange={
                                                                                handleCheckboxChange
                                                                            }
                                                                        />
                                                                        <Label
                                                                            check
                                                                        >
                                                                            <p>
                                                                                I
                                                                                understand
                                                                                and
                                                                                agree
                                                                                to
                                                                                the
                                                                                <Link to='/terms-and-conditions'>
                                                                                    Terms
                                                                                    &
                                                                                    Conditions
                                                                                </Link>

                                                                                ,{" "}
                                                                                <Link to='/privacy-policy'>
                                                                                    Privacy
                                                                                    Policy
                                                                                </Link>

                                                                                ,
                                                                                and{" "}
                                                                                <Link to='refund-policy'>
                                                                                    Return
                                                                                    Refund
                                                                                    Policy
                                                                                </Link>
                                                                            </p>
                                                                        </Label>
                                                                    </FormGroup>
                                                                </Form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                            {!loading &&
                                                !showOTP &&
                                                !paymentFailed &&
                                                !paymentSuccess &&
                                                status_code != 200 &&
                                                status_code != 500 && (
                                                    <ButtonSubmit
                                                        form={form}
                                                        setForm={setForm}
                                                        handleSubmit={
                                                            handleFormSubmit
                                                        }
                                                        loading={loading}
                                                        setLoading={setLoading}
                                                        disabled={
                                                            paymentMethodError
                                                        }
                                                    />
                                                )}
                                            {/* {product?.category_id !== 12 && (
                                                <div className="text-center">
                                                    <p className="mb-0">
                                                        Caution : Please share valid
                                                        email address to get the product
                                                        delivered
                                                    </p>
                                                </div>
                                        )} */}
                                        </div>
                                        {loading && status_code != 200 && (
                                            <SecurePaymentInProgress
                                                selectedChanel={selectedChanel}
                                            />
                                        )}
                                        {showOTP &&
                                            !loading &&
                                            !paymentFailed &&
                                            !paymentSuccess &&
                                            status_code != 200 &&
                                            status_code != 500 && (
                                                <SecurePaymentOTP
                                                    order_id={orderId}
                                                    setLoading={setLoading}
                                                    form={form}
                                                    setPaymentSuccess={
                                                        setPaymentSuccess
                                                    }
                                                    setPaymentFailed={
                                                        setPaymentFailed
                                                    }
                                                    errorMsg={errorMsg}
                                                    setErrMsg={setErrMsg}
                                                    setOTPShow={setOTPShow}
                                                    otpSubmitRemaining={
                                                        otpSubmitRemaining
                                                    }
                                                    setotpSubmitRemaining={
                                                        setotpSubmitRemaining
                                                    }
                                                    resetState={resetAll}
                                                />
                                            )}
                                        {((!loading &&
                                            !showOTP &&
                                            paymentSuccess &&
                                            !paymentFailed) ||
                                            status_code == 200) && (
                                            <SecurePaymentSuccess
                                                product={product}
                                                selectedChanel={selectedChanel}
                                            />
                                        )}
                                        {((!loading &&
                                            !showOTP &&
                                            paymentFailed &&
                                            !paymentSuccess) ||
                                            status_code == 500) && (
                                            <SecurePaymentFailed
                                                selectedChanel={selectedChanel}
                                                message={errorMsg}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {!loading &&
                            !showOTP &&
                            !paymentSuccess &&
                            !paymentFailed &&
                            status_code != 200 &&
                            status_code != 500 && (
                                <div className='modal-footer d-flex d-lg-none justify-content-between '>
                                    <div className='payable-amounts'>
                                        <span>
                                            ৳ {product && product.price}
                                        </span>
                                    </div>
                                    <button
                                        type='button'
                                        className='btn btn-primary'
                                        onClick={handleFormSubmit}
                                        disabled={paymentMethodError}
                                    >
                                        BUY
                                    </button>
                                </div>
                            )}
                    </div>
                </div>
            </div>
            {(status_code == "200" || status_code == "500" || product) && (
                <div className='modal-backdrop fade show'></div>
            )}
        </Fragment>
    );
}
