import { useParams } from "react-router-dom";
import BanglalinkDobConsent from "../components/DOB/Consent/Banglalink";

export default function DobConsentPage() {
    const { operator } = useParams();

    switch (operator) {
        case "bl-dob":
            return <BanglalinkDobConsent />;

        default:
            return <BanglalinkDobConsent />;
    }
}
