import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import FullPageLoader from "../../components/partials/loader/full-page";
import "../../components/truecaller/style.css";
import {
    mygp_with_reference,
    mygp_without_reference,
} from "../../constants/truecaller.constants";
import {
    getLastOrders,
    unsubscribeTruecaller,
    updateOrderStatus,
} from "../../helpers/api.helper";
import NotPurchased from "./landing-page/not-purchased";
import Purchased from "./landing-page/purchased";

export default function TruecallerLandingPage({ reference }) {
    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);
    const msisdn = searchParams.get("msisdn");
    const package_name = searchParams.get("package");
    const order_param = searchParams.get("order");
    const status_param = searchParams.get("status");
    const [loading, setLoading] = useState(false);
    const [gettingOrder, setGettingOrder] = useState(false);
    const [purchasedAt, setPurchasedAt] = useState(null);
    const [hasPurchased, setHasPurchased] = useState(false);
    const [hasUnsubscribed, setUnsubscribed] = useState(false);
    const [order, setOrder] = useState(null);
    const [fetch, setFetch] = useState(true);

    useEffect(() => {
        // Access the gtag function from the window object
        if (window && window.gtag) {
            // Trigger the conversion event after the component is mounted
            window.gtag("event", "click", {
                send_to: "11289827891",
                event_category: "Add to cart",
                event_label: "ksfECI64rdMYELO0tIcq",
            });
        }
    }, []);

    useEffect(() => {
        if (!fetch) return;
        if (msisdn) {
            getLastOrder(msisdn);
        }
        setFetch(false);
    }, [fetch]);

    useEffect(() => {
        if (order_param && status_param) {
            updateStatus();
        }
    }, [order_param, status_param]);

    const updateStatus = async () => {
        const res = await updateOrderStatus(order_param, status_param);
    };

    const refetch = () => setFetch(true);

    const getLastOrder = async (msisdn) => {
        setGettingOrder(true);
        const res_last_order = await getLastOrders(msisdn);
        setGettingOrder(false);
        if (
            res_last_order?.success &&
            Array.isArray(res_last_order?.data) &&
            res_last_order?.data?.length > 0
        ) {
            const last_order = res_last_order?.data[0];
            setOrder(last_order);
            setHasPurchased(true);
            setPurchasedAt(
                moment(last_order?.last_renew_attempt_at).format(
                    "ddd MMM DD YYYY HH:mm:ss [GMT] ZZ (zz)"
                )
            );
            setUnsubscribed(last_order?.auto_renew ? false : true);
        }
    };

    const getPackageValidity = (pckg) => {
        if (pckg === "daily") {
            return 1;
        } else if (pckg === "weekly") {
            return 7;
        } else if (pckg === "monthly") {
            return 30;
        } else {
            return 0;
        }
    };

    const validTill = (time) => {
        const product_id =
            typeof order === "object" && order?.product_id
                ? order?.product_id
                : null;

        const package_order = product_id
            ? product_id === 37
                ? "daily"
                : product_id === 38
                ? "weekly"
                : "monthly"
            : null;

        const dateString = time;

        // Convert the date string to a Moment object
        const initialDate = moment(dateString, "ddd MMM DD YYYY HH:mm:ss ZZ");

        // Add 30 days
        const thirtyDaysLater = moment(initialDate)
            .add(getPackageValidity(package_order || package_name), "days")
            .format("MMMM Do YYYY, h:mm:ss a");

        return thirtyDaysLater;
    };

    const handleUnsubscribe = async () => {
        const product_id =
            typeof order === "object" && order?.product_id
                ? order?.product_id
                : null;

        setLoading(true);
        const res = await unsubscribeTruecaller(
            msisdn,
            product_id
                ? product_id
                : package_name === "monthly"
                ? 39
                : package_name === "weekly"
                ? 38
                : package_name === "daily"
                ? 37
                : null
        );
        setLoading(false);
        if (res?.success) {
            toast.success(res?.message);
            setUnsubscribed(true);
            if (res?.package && res?.package?.purchased_at) {
                const purchased_at = moment(res?.package?.purchased_at).format(
                    "ddd MMM DD YYYY HH:mm:ss [GMT] ZZ (zz)"
                );
                setPurchasedAt(purchased_at);
            }
        } else {
            toast.error(res?.message);
        }
    };

    if (gettingOrder)
        return (
            <div className='loading'>
                <div className='lds-facebook'>
                    <div />
                    <div />
                    <div />
                </div>
            </div>
        );

    return (
        <Fragment>
            {hasPurchased ? (
                <Purchased
                    hasUnsubscribed={hasUnsubscribed}
                    validTill={validTill(purchasedAt)}
                    handleUnsubscribe={handleUnsubscribe}
                />
            ) : (
                <NotPurchased
                    msisdn={msisdn}
                    refetch={refetch}
                    ref_1={reference || mygp_with_reference}
                    ref_2={reference || mygp_without_reference}
                />
            )}
            {loading ? <FullPageLoader /> : ""}
        </Fragment>
    );
}
