import axios from "axios";
import { BASE_URL } from "../../../config";

const MAX_RETRY_ATTEMPTS = 5;

export const createAxiosInstance = () => {
    // Create an Axios instance
    const instance = axios.create({
        baseURL: BASE_URL, // Replace with your API base URL
        headers: {
            "Content-Type": "application/json",
            // Add other headers as needed
        },
    });

    // Add a response interceptor for retrying requests
    instance.interceptors.response.use(
        (response) => {
            // If the response is successful, return it
            return response;
        },
        async (error) => {
            const { config, response } = error;

            if (
                response &&
                response.status === 403 &&
                config.retryCount < MAX_RETRY_ATTEMPTS
            ) {
                // Retry the request
                console.log(
                    `Retrying API call. Attempt ${config.retryCount + 1}`
                );
                config.retryCount = config.retryCount
                    ? config.retryCount + 1
                    : 1;

                return new Promise((resolve) => {
                    setTimeout(
                        () => resolve(instance(config)),
                        config.retryDelay || 5000
                    );
                });
            }

            // If maximum retry attempts reached or non-403 status code, reject with the error
            return Promise.reject(error);
        }
    );

    return instance;
};
