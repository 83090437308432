
import { truecaller_packages } from "../../../constants/truecaller.constants";
import '../style/campaign-styles.css';
import '../../../components/truecaller/style.css';
import { Fragment, useEffect, useState } from "react";
import FullPageLoader from "../../../components/partials/loader/full-page";
import TrueCallerFormModal from "../../../components/modals/truecaller/truecaller.form";
import TruecallerOTP from "../../../components/modals/truecaller/truecaller.otp";


export default function Digitantra({ reference }) {

    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [modal_2, setModal_2] = useState(false);
    const [phone, setPhone] = useState('');
    const [packName, setPackName] = useState(null);

    const toggle = () => { setModal(!modal); setLoading(false); }
    const toggle_2 = () => { setModal_2(!modal_2); setLoading(false); }

    useEffect(() => {
        // Initializing Google Analytics gtag.js
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            window.dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', 'AW-11289827891');
    }, []);


    return (
        <Fragment>
            <div className='screen'>
                <div className="screen-row">
                    <div className="header">
                        <h2>Trust your communication with</h2>
                        <img src="/assets/images/TruecallerPremium.png" alt="" />
                    </div>
                    <div className="body">
                        <img src="/assets/images/Mobile.png" alt="" />
                    </div>
                    <div className="footer">
                        <ul>
                            {truecaller_packages.filter(e => e?.id === 39).map((item, indx) => (
                                <li key={indx}>
                                    <div onClick={() => {
                                        setPackName(item?.slug);
                                        setModal(true);
                                    }}>
                                        <img src={item?.src} alt={item?.title} />
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <p>* 15% VAT + 1% SC Applicable</p>
                    </div>
                </div>
            </div>
            {loading ? <FullPageLoader /> : ""}
            {modal && <TrueCallerFormModal
                modal={modal}
                toggle={toggle}
                setLoading={setLoading}
                setMsisdn={setPhone}
                setModal_2={setModal_2}
                package_name={packName}
                reference={reference}
            />}
            {modal_2 && <TruecallerOTP
                modal={modal_2}
                toggle={toggle_2}
                setLoading={setLoading}
                msisdn={phone}
                package_name={packName}
                refetch={null}
            />}
        </Fragment>

    );
}