import { useParams } from "react-router-dom";
import "../../components/truecaller/style.css";
import { truecaller_campaign_partners } from "../../constants/truecaller.constants";
import TruecallerBkash from "./campaigns/Bkash";
import Digitantra from "./campaigns/Digitantra";
import TruecallerNagad from "./campaigns/Nagad";
import TruecallerLandingPage from "./truecaller-landing";

export default function TruecallerCampaigns() {
    const { reference } = useParams();

    const getCampSlug = (ref) => {
        const partner = truecaller_campaign_partners.filter(
            (e) => e?.slug === ref
        )[0];

        return partner ? partner?.ref : ref;
    };

    switch (reference) {
        case "rm":
            return <Digitantra reference={getCampSlug("rm")} />;
        case "nagad":
            return <TruecallerNagad reference={getCampSlug("nagad")} />;
        case "bkash":
            return <TruecallerBkash reference={getCampSlug("bkash")} />;

        default:
            return <TruecallerLandingPage reference={getCampSlug(reference)} />;
    }
}
