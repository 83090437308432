import { useEffect, useState } from "react";

export default function Channels({
    channels = [],
    selectedChanel = {},
    setChanel = {},
    product,
}) {
    const [paymentChanels, setPaymentChanels] = useState([]);
    useEffect(() => {
        // setChanel(channels[0]);
        if (Array.isArray(channels) && channels.length > 0) {
            const list = [];
            for (let i = 0; i < channels.length; i++) {
                const chanel = channels[i];
                if (
                    [2, 6].indexOf(chanel?.payment_channel_id || 0) !== -1 &&
                    (product?.price || 0) < 30
                ) {
                    continue;
                } else if (
                    (chanel?.payment_channel_id === 8 ||
                        chanel?.payment_channel_id === 9 ||
                        chanel?.payment_channel_id === 10) &&
                    product?.price > 200
                ) {
                    continue;
                }

                list.push(chanel);
            }
            if (list.length > 0) {
                setChanel(list[0]);
                setPaymentChanels(list);
            }
        }
    }, [channels, product]);

    if (!channels || !channels.length) return <h1>No Channel Found</h1>;

    return (
        <div className='channels'>
            <div className='title text-center'>
                <h2>Pay With</h2>
            </div>
            <div className='pay-body px-4 pb-3'>
                <div className='row justify-content-center row-cols-auto g-3'>
                    {paymentChanels.map((item, indx) => (
                        <div
                            className='col'
                            key={indx}
                            onClick={() => setChanel(item)}
                        >
                            {/* <input
                                type="radio"
                                className="btn-check"
                                name="payment-method"
                                id={item.payment_channel_id}
                                autoComplete="off"
                                defaultChecked
                            /> */}
                            <label
                                className='btn btn-outline-primary'
                                htmlFor='pm-1'
                                style={
                                    item.payment_channel_id ==
                                    selectedChanel.payment_channel_id
                                        ? {
                                              borderStyle: "solid",
                                              borderColor: "#2fb6e9",
                                              borderWidth: "2px",
                                          }
                                        : {}
                                }
                            >
                                <img
                                    src={item.thumbnail_url}
                                    alt={item.name}
                                    className='img-fluid'
                                />
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
