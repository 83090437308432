import { makeGenre, moveKeyToFirst } from "../../helpers/helper";

export default function NavBar({ products = [] }) {
    const genres = makeGenre(moveKeyToFirst(products, "TrueCaller"));

    return (
        <section className='section-navigation nav-sticky' id='navbar-example2'>
            <div className='container'>
                <div className='s-nav'>
                    <div className='nav justify-content-center'>
                        {genres.map((item, indx) => (
                            <div className='nav-item px-3' key={indx}>
                                <a
                                    className={`nav-link `}
                                    href={`#${item.slug}`}
                                >
                                    {item.title}
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}
