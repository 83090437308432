export const paymentChannels = [
    {
        id: 1,
        name: "BKASH",
    },
    {
        id: 2,
        name: "NAGAD",
    },
    {
        id: 3,
        name: "UPAY",
    },
    {
        id: 4,
        name: "DBBL-MOBILE",
    },
    {
        id: 5,
        name: "DBBL-NEXUS",
    },
    {
        id: 6,
        name: "VISA",
    },
    {
        id: 7,
        name: "MASTER",
    },
    {
        id: 8,
        name: "GRAMEENPHONE",
    },
    {
        id: 9,
        name: "BANGLALINK",
    },
    {
        id: 10,
        name: "ROBI",
    },
];
