import { useState } from "react";
import { Link } from "react-router-dom";
import { useProfile } from "../../../contexts/profile/use-profile";

export default function Header() {
    const [showNav, setShowNav] = useState(false);
    const { user, addUser } = useProfile();
    const path = window.location.pathname;

    const logoutUser = () => {
        addUser(null);
        localStorage.removeItem("user_msisdn");
    };

    // useEffect(() => {
    //     console.log('showNav: ', showNav);
    // }, [showNav]);

    return (
        <header className='header-area'>
            <nav className='navbar navbar-expand-lg fixed-top navbar-dark bg-dark'>
                <div className='container'>
                    <Link className='navbar-brand d-none d-lg-block' to='/'>
                        <img
                            src='assets/images/hullor-logo.png'
                            alt='hullor-logo'
                        />
                    </Link>
                    {/* <div className="search ms-5 d-none d-lg-block">
                        <form className="d-flex">
                            <div className="input-group">
                                <span
                                    className="input-group-text"
                                    id="basic-addon1"
                                >
                                    <i className="fa fa-search" />
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search"
                                    aria-label="Search"
                                    aria-describedby="basic-addon1"
                                />
                            </div>
                        </form>
                    </div> */}
                    <div className='navbar flex-grow-1'>
                        <div className='d-lg-none xs-head px-3'>
                            <div className='row align-items-center justify-content-center'>
                                <div className='col-auto d-none'>
                                    <a href=''>
                                        <i className='fa fa-arrow-left' />
                                    </a>
                                </div>
                                <div className='col'>
                                    <Link className='navbar-brand' to='/'>
                                        <img
                                            src='assets/images/hullor-logo.png'
                                            alt='hullor-logo'
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <ul className='navbar-nav ms-3 ms-lg-auto mb-2 mb-lg-0'>
                            <li className='nav-item dropdown'>
                                <button
                                    className={`nav-link bg-primary bg-gradient active ${
                                        showNav && "show"
                                    }`}
                                    type='button'
                                    id='menu-drp'
                                    data-bs-toggle='dropdown'
                                    aria-expanded='false'
                                    onClick={() => {
                                        setShowNav(!showNav);
                                    }}
                                >
                                    <img
                                        src='assets/images/user.svg'
                                        alt='user-avatar'
                                        width={16}
                                    />
                                </button>
                                <ul
                                    className={`dropdown-menu bg-black rounded start-50 translate-middle-x ${
                                        showNav && "show"
                                    }`}
                                    aria-labelledby='menu-drp'
                                >
                                    <li
                                        className={`${path == "/" && "d-none"}`}
                                    >
                                        <Link className='dropdown-item' to='/'>
                                            Home
                                        </Link>
                                    </li>
                                    <li
                                        className={`${
                                            path == "/contact-us" && "d-none"
                                        }`}
                                    >
                                        <Link
                                            className='dropdown-item'
                                            to='/contact-us'
                                        >
                                            Contact Us
                                        </Link>
                                    </li>
                                    <li
                                        className={`${
                                            (user == null ||
                                                path == "/profile") &&
                                            "d-none"
                                        }`}
                                    >
                                        <Link
                                            className='dropdown-item'
                                            to='/profile'
                                        >
                                            Profile
                                        </Link>
                                    </li>
                                    <li
                                        className={`${
                                            (path == "/login" ||
                                                user != null) &&
                                            "d-none"
                                        }`}
                                    >
                                        <Link
                                            className='dropdown-item'
                                            to='/login'
                                        >
                                            Login Or Signup
                                        </Link>
                                    </li>
                                    <li
                                        className={`${
                                            user == null && "d-none"
                                        }`}
                                    >
                                        <Link
                                            className='dropdown-item'
                                            to='/'
                                            onClick={logoutUser}
                                        >
                                            Logout
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            {/* END NAVBAR */}
        </header>
    );
}
