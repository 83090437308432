export default function MainSlider() {
    return (
        <div
            className='main-slider position-relative'
            style={{ backgroundImage: "url(assets/images/hullor-banner.png)" }}
        >
            <img src='/assets/images/hullor-banner.jpg' className='img-fluid' />
            <div className='container d-none'>
                <div className='slider-contents d-flex align-items-center justify-content-end'>
                    <div className='slider-inner'>
                        {/* <div className="slider-header text-white">
                            <h1 className="buy">BUY</h1>
                            <h2 className="global">GLOBAL</h2>
                            <h1 className="pay">PAY</h1>
                            <h2 className="local">LOCAL</h2>
                        </div> */}
                    </div>
                </div>
            </div>
            {/* END */}
        </div>
    );
}
