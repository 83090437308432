import { useCallback, useEffect, useState } from "react";
import Footer from "../components/layout/footer/footer";
import Header from "../components/layout/header/header";
import { submitContactForm } from "../helpers/api.helper";
import { validateEmail, validatePhone } from "../helpers/helper";

export default function ContactUs() {
    const [form, setForm] = useState({
        name: {
            value: "",
            isValid: true,
        },
        email: {
            value: "",
            isValid: true,
        },
        phone: {
            value: "",
            isValid: true,
        },
        message: {
            value: "",
            isValid: true,
        },
    });
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({
        success: {
            value: false,
            message: "",
        },
        error: {
            value: false,
            message: "",
        },
    });

    useEffect(() => {
        window.scrollTo(0, 0);

        // loading bootstrap plugin
        const script2 = document.createElement("script");
        script2.src = "/assets/js/bootstrap.bundle.min.js";
        script2.async = true;
        document.body.appendChild(script2);

        // loading slick plugin
        const script3 = document.createElement("script");
        script3.src = "/assets/vendor/slick/slick.min.js";
        script3.async = true;
        document.body.appendChild(script3);

        //loading aos plugin
        const script4 = document.createElement("script");
        script4.src = "/assets/vendor/aos/aos.js";
        script4.async = true;
        document.body.appendChild(script4);

        // loading main js file
        const script1 = document.createElement("script");
        script1.src = "/assets/js/main.js";
        script1.async = true;
        document.body.appendChild(script1);

        return () => {
            document.body.removeChild(script1);
            document.body.removeChild(script2);
            document.body.removeChild(script3);
            document.body.removeChild(script4);
        };
    }, []);

    const formSubmitHandler = () => {
        console.log("form: ", form);
        setLoading(true);
        const isEmailValid = validateEmail(form.email.value);
        const isPhoneValid = validatePhone(form.phone.value);
        const isNameValid = form.name.value.length > 0;
        const isMessageValid = form.message.value.length > 0;

        setForm({
            ...form,
            email: { ...form.email, isValid: isEmailValid },
            phone: { ...form.phone, isValid: isPhoneValid },
            name: { ...form.name, isValid: isNameValid },
            message: { ...form.message, isValid: isMessageValid },
        });

        if (isEmailValid && isPhoneValid && isNameValid && isMessageValid) {
            callSubmitAPI();
            setForm({
                name: {
                    value: "",
                    isValid: true,
                },
                email: {
                    value: "",
                    isValid: true,
                },
                phone: {
                    value: "",
                    isValid: true,
                },
                message: {
                    value: "",
                    isValid: true,
                },
            });
        }
        setLoading(false);
    };
    const callSubmitAPI = useCallback(async () => {
        const response = await submitContactForm(form);
        console.log(response);
        if (response.success && response.status_code == 200) {
            // swal('Success', 'Message was sent...', 'success');
            setMessage({
                ...message,
                success: { ...message.success, value: true },
                error: { ...message.error, value: false },
            });
        } else if (response.error_message) {
            // swal('Error', response.error_message, 'error');
            setMessage({
                ...message,
                error: {
                    ...message.error,
                    value: true,
                    message: response.message,
                },
                success: { ...message.success, value: false },
            });
        } else {
            // swal('Server Error', response.error, 'error');
            console.log("error: ", response.message);
            setMessage({
                ...message,
                error: {
                    ...message.error,
                    value: true,
                    message: response.message,
                },
                success: { ...message.success, value: false },
            });
        }
        console.log("message: ", message);
    });

    return (
        <div className='main-wrapper'>
            <Header />
            <main className='main-area'>
                <section className='contact-us-page'>
                    <div className='container'>
                        <div className='contact py-5'>
                            <div className='row'>
                                <div className='col d-none d-lg-block'></div>
                                <div className='col-lg-7'>
                                    <div className='contact-form'>
                                        <div
                                            className='text-center pb-3'
                                            data-aos='fade-up'
                                            data-aos-duration={500}
                                        >
                                            <h2 className='text-primary'>
                                                Get in touch!
                                            </h2>
                                            <p>Contact us for any issue</p>
                                        </div>
                                        {(message.success.value ||
                                            message.error.value) && (
                                            <div
                                                className={`text-center ${
                                                    message.success.value &&
                                                    "text-success"
                                                } ${
                                                    message.error.value &&
                                                    "text-danger"
                                                } pb-3`}
                                            >
                                                {message.success.value
                                                    ? "Successfully Sent Email.."
                                                    : message.error.message}
                                            </div>
                                        )}
                                        <div
                                            className='shadow form-fileds p-5 bg-light'
                                            data-aos='fade-up'
                                            data-aos-duration={1000}
                                        >
                                            <form action='#'>
                                                <div className='row align-items-center'>
                                                    <div className='col-sm-6 pe-0'>
                                                        <h3 className='text-primary'>
                                                            Contact Form
                                                        </h3>
                                                        <div className='mb-2'>
                                                            <label
                                                                htmlFor='name'
                                                                className='form-label'
                                                            >
                                                                Name
                                                            </label>
                                                            <input
                                                                type='text'
                                                                className='form-control'
                                                                id='name'
                                                                placeholder='Enter your name'
                                                                value={
                                                                    form.name
                                                                        .value
                                                                }
                                                                onChange={(e) =>
                                                                    setForm({
                                                                        ...form,
                                                                        name: {
                                                                            ...form.name,
                                                                            value: e
                                                                                .target
                                                                                .value,
                                                                        },
                                                                    })
                                                                }
                                                            />
                                                            {form.name
                                                                .isValid ==
                                                                false && (
                                                                <p
                                                                    style={{
                                                                        color: "red",
                                                                    }}
                                                                >
                                                                    Name can not
                                                                    be empty
                                                                </p>
                                                            )}
                                                        </div>
                                                        <div className='mb-2'>
                                                            <label
                                                                htmlFor='number'
                                                                className='form-label'
                                                            >
                                                                Mobile Number
                                                            </label>
                                                            <input
                                                                className='form-control'
                                                                id='number'
                                                                placeholder='Enter your number'
                                                                value={
                                                                    form.phone
                                                                        .value
                                                                }
                                                                onChange={(e) =>
                                                                    setForm({
                                                                        ...form,
                                                                        phone: {
                                                                            ...form.phone,
                                                                            value: e
                                                                                .target
                                                                                .value,
                                                                        },
                                                                    })
                                                                }
                                                            />
                                                            {form.phone
                                                                .isValid ==
                                                                false && (
                                                                <p
                                                                    style={{
                                                                        color: "red",
                                                                    }}
                                                                >
                                                                    Please Enter
                                                                    a Valid
                                                                    Phone no.
                                                                </p>
                                                            )}
                                                        </div>
                                                        <div className='mb-2'>
                                                            <label
                                                                htmlFor='email'
                                                                className='form-label'
                                                            >
                                                                Email
                                                            </label>
                                                            <input
                                                                type='email'
                                                                className='form-control'
                                                                id='email'
                                                                placeholder='Enter your email'
                                                                value={
                                                                    form.email
                                                                        .value
                                                                }
                                                                onChange={(e) =>
                                                                    setForm({
                                                                        ...form,
                                                                        email: {
                                                                            ...form.email,
                                                                            value: e
                                                                                .target
                                                                                .value,
                                                                        },
                                                                    })
                                                                }
                                                            />
                                                            {form.email
                                                                .isValid ==
                                                                false && (
                                                                <p
                                                                    style={{
                                                                        color: "red",
                                                                    }}
                                                                >
                                                                    Please Enter
                                                                    a Valid
                                                                    Email
                                                                    Address
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6 ps-0'>
                                                        <textarea
                                                            name='message'
                                                            className='form-control'
                                                            rows={10}
                                                            placeholder='Message'
                                                            value={
                                                                form.message
                                                                    .value
                                                            }
                                                            onChange={(e) =>
                                                                setForm({
                                                                    ...form,
                                                                    message: {
                                                                        ...form.message,
                                                                        value: e
                                                                            .target
                                                                            .value,
                                                                    },
                                                                })
                                                            }
                                                        />
                                                        {form.message.isValid ==
                                                            false && (
                                                            <p
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                Message can not
                                                                be empty{" "}
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className='form-action'>
                                                    {!loading && (
                                                        <button
                                                            className='btn btn-primary shadow text-white'
                                                            type='submit'
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                formSubmitHandler();
                                                            }}
                                                        >
                                                            Send
                                                        </button>
                                                    )}
                                                    {loading && (
                                                        <button
                                                            className='btn btn-primary shadow text-white'
                                                            type='submit'
                                                        >
                                                            <span
                                                                className='spinner-border'
                                                                role='status'
                                                                aria-hidden='true'
                                                            />
                                                        </button>
                                                    )}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className='col d-none d-lg-block'></div>
                            </div>
                        </div>
                        <div className='text-center'>
                            <h5>Contact Us</h5>
                            <p>
                                Address: Rangs Babylonia, level-6, 246 Bir Uttam
                                Mir Shawkat Ali Road, Tejgao IA, Dhaka-1217
                            </p>
                            <p>Call: 09611116303</p>
                            <p>
                                Or send an email with details at
                                supports@momagicbd.com
                            </p>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}
