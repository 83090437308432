import { useEffect } from "react";
import Footer from "../components/layout/footer/footer";
import Header from "../components/layout/header/header";

export default function AboutUs() {
    useEffect(() => {
        window.scrollTo(0, 0);

        // loading bootstrap plugin
        const script2 = document.createElement("script");
        script2.src = "/assets/js/bootstrap.bundle.min.js";
        script2.async = true;
        document.body.appendChild(script2);

        // loading slick plugin
        const script3 = document.createElement("script");
        script3.src = "/assets/vendor/slick/slick.min.js";
        script3.async = true;
        document.body.appendChild(script3);

        //loading aos plugin
        const script4 = document.createElement("script");
        script4.src = "/assets/vendor/aos/aos.js";
        script4.async = true;
        document.body.appendChild(script4);

        // loading main js file
        const script1 = document.createElement("script");
        script1.src = "/assets/js/main.js";
        script1.async = true;
        document.body.appendChild(script1);

        return () => {
            document.body.removeChild(script1);
            document.body.removeChild(script2);
            document.body.removeChild(script3);
            document.body.removeChild(script4);
        };
    }, []);

    return (
        <div className='main-wrapper'>
            <Header />
            <main className='main-area'>
                <section className='contact-us-page'>
                    <div className='container'>
                        <div className='contact py-5'>
                            <div className='text-center'>
                                <strong>About Us</strong>
                            </div>
                            <div>
                                <span>
                                    <b>hullor.io</b> is an online marketplace by
                                    MoMagic Bangladesh Ltd (BIN: 001006111-0203)
                                    where users can purchase many digital goods
                                    & services through their mobile wallet or
                                    Mobile Financial Services (bkash/Nagad etc).
                                    MoMagic acts as your trusted partner in the
                                    world of digital solutions and mobile
                                    technology. They have been the pioneer of
                                    handheld device-based value-added services
                                    in Bangladesh. They offer digital payment
                                    technology to help market, merchandise, and
                                    monetize products more effectively to online
                                    and offline customers worldwide. MoMagic
                                    partner with the world's leading gaming and
                                    digital companies to boost their growth and
                                    scale their businesses. They are one of the
                                    very few license operators connected with
                                    all Telecoms in Bangladesh to collect
                                    license fee payments from end users.
                                </span>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}
