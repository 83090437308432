import { Fragment } from "react";

export default function CustomerDetailsForm({
    form = {},
    setForm = {},
    selectedChanel,
    extraFields = {},
    additionalData = {},
    setAdditionalData = {},
    product = {},
}) {
    return (
        <div
            className={product?.category_id === 12 ? "" : "customer-details"}
            style={
                product?.category_id === 12
                    ? {
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          textAlign: "center",
                      }
                    : {}
            }
        >
            <div
                className={`row gx-5 row-cols-1 row-cols-lg-${
                    product?.category_id === 12 ? "1" : "2"
                }`}
            >
                {product?.category_id !== 12 && (
                    <div className='col'>
                        <div>
                            <label htmlFor='email' className='form-label'>
                                Email
                            </label>
                            <input
                                type='email'
                                className='form-control'
                                id='email'
                                placeholder='Enter your email'
                                onChange={(e) =>
                                    setForm({
                                        ...form,
                                        email: {
                                            ...form.email,
                                            value: e.target.value,
                                        },
                                    })
                                }
                                value={form.email.value ? form.email.value : ""}
                            />
                            {form.email.isValid == false && (
                                <p style={{ color: "red" }}>
                                    Please Enter a Valid Email Address
                                </p>
                            )}
                        </div>
                    </div>
                )}
                <div className='col'>
                    <div>
                        <label htmlFor='number' className='form-label'>
                            {product?.category_id === 12
                                ? selectedChanel?.payment_channel_id === 8
                                    ? "GrameenPhone Number"
                                    : selectedChanel?.payment_channel_id === 2
                                    ? "Mobile Number"
                                    : "Mobile Number"
                                : "Mobile Number"}
                        </label>
                        <input
                            type='text'
                            className='form-control'
                            id='number'
                            placeholder='017 XXXX XXXX'
                            onChange={(e) =>
                                setForm({
                                    ...form,
                                    phone: {
                                        ...form.phone,
                                        value: e.target.value,
                                    },
                                })
                            }
                            value={form.phone.value ? form.phone.value : ""}
                        />
                        {form.phone.isValid == false && (
                            <p style={{ color: "red" }}>
                                Please Enter a Valid Phone Number
                            </p>
                        )}
                    </div>
                </div>
                {extraFields &&
                    Object.keys(extraFields).length > 0 &&
                    Object.entries(extraFields).map(([key, value]) => (
                        <Fragment key={key}>
                            <div className='col'>
                                <div>
                                    <label
                                        htmlFor='player_id'
                                        className='form-label'
                                    >
                                        {value.title}
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        id={value.key}
                                        placeholder={`Enter ${value.title}`}
                                        value={additionalData[value.key]}
                                        onChange={(e) =>
                                            setAdditionalData({
                                                ...additionalData,
                                                [value.key]: e.target.value,
                                            })
                                        }
                                        style={{
                                            background: "#fafbff",
                                            border: "2px solid #f2f3f5",
                                            boxSizing: "border-box",
                                            borderRadius: "12px",
                                            padding: "20px",
                                        }}
                                    />
                                </div>
                            </div>
                        </Fragment>
                    ))}
            </div>
        </div>
    );
}
