import { useEffect, useState } from "react";
import Footer from "../components/layout/footer/footer";
import Header from "../components/layout/header/header";
import ProfileUpdateForm from "../components/profile/ProfileUpdateForm";
import PurchaseHistory from "../components/profile/purchaseHistory";
import Support from "../components/profile/support";
import { getPurchaseHistory } from "../helpers/api.helper";

const purchases = [
    {
        id: 1,
        date: "15-09-21",
        product: "Vikings 10$",
        code: "GS1231234",
    },
    {
        id: 2,
        date: "15-09-21",
        product: "Vikings 10$",
        code: "GS1231234",
    },
    {
        id: 3,
        date: "15-09-21",
        product: "Vikings 10$",
        code: "GS1231234",
    },
    {
        id: 4,
        date: "15-09-21",
        product: "Vikings 10$",
        code: "GS1231234",
    },
    {
        id: 5,
        date: "15-09-21",
        product: "Vikings 10$",
        code: "GS1231234",
    },
];

export default function Profile() {
    const [responseData, setResponseData] = useState(null);
    let user;
    try {
        user = localStorage.getItem("user_msisdn");
    } catch (err) {
        console.log("error while getting user msisdn...");
        user = {};
    }

    useEffect(() => {
        window.scrollTo(0, 0);

        // loading bootstrap plugin
        const script2 = document.createElement("script");
        script2.src = "/assets/js/bootstrap.bundle.min.js";
        script2.async = true;
        document.body.appendChild(script2);

        // loading slick plugin
        const script3 = document.createElement("script");
        script3.src = "/assets/vendor/slick/slick.min.js";
        script3.async = true;
        document.body.appendChild(script3);

        //loading aos plugin
        const script4 = document.createElement("script");
        script4.src = "/assets/vendor/aos/aos.js";
        script4.async = true;
        document.body.appendChild(script4);

        // loading main js file
        const script1 = document.createElement("script");
        script1.src = "/assets/js/main.js";
        script1.async = true;
        document.body.appendChild(script1);

        return () => {
            document.body.removeChild(script2);
            document.body.removeChild(script3);
            document.body.removeChild(script4);
            document.body.removeChild(script1);
        };
    });
    useEffect(async () => {
        // console.log('purchase history: ', await getPurchaseHistory('880181198965'));
        setResponseData(await getPurchaseHistory(user));
    }, []);
    if (!responseData) return <h1>loading...</h1>;

    return (
        <div className='main-wrapper'>
            <Header />
            <div className='main-area'>
                <section className='profile-page position-relative'>
                    <div className='container'>
                        <div className='profile'>
                            <div className='basic-info text-center pt-5'>
                                <h1 data-aos='fade-up' data-aos-duration={1000}>
                                    Hello ! <br /> Welcome to your profile
                                </h1>
                                <ProfileUpdateForm user={user} />
                                <Support />
                            </div>
                            {/* END BASIC INFO */}
                            {responseData.success &&
                            responseData.purchase_history.length ? (
                                <PurchaseHistory
                                    purchases={responseData.purchase_history}
                                />
                            ) : (
                                <div className='text-center pb-5'>
                                    <h3>No Purchase History</h3>
                                </div>
                            )}
                            {responseData.error && (
                                <div className='text-center pb-5'>
                                    <h1>{responseData.error}</h1>
                                </div>
                            )}
                            {!responseData.success &&
                                responseData.error_message && (
                                    <div className='text-center pb-5'>
                                        <h1>{responseData.error_message}</h1>
                                    </div>
                                )}
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    );
}
