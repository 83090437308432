export default function LoginSlider() {
    return (
        <div className='col-xl-6 p-0 d-none d-lg-block'>
            <div
                className='login-slider-area'
                data-aos='fade-in'
                data-aos-duration={1000}
            >
                <div className='login-slider'>
                    <div>
                        <img
                            className='img-fluid'
                            src='/assets/images/login-slider.jpg'
                            alt='login-slider'
                        />
                    </div>
                    <div>
                        <img
                            className='img-fluid'
                            src='/assets/images/login-slider.jpg'
                            alt='login-slider'
                        />
                    </div>
                    <div>
                        <img
                            className='img-fluid'
                            src='/assets/images/login-slider.jpg'
                            alt='login-slider'
                        />
                    </div>
                    <div>
                        <img
                            className='img-fluid'
                            src='assets/images/login-slider.jpg'
                            alt='login-slider'
                        />
                    </div>
                    <div>
                        <img
                            className='img-fluid'
                            src='assets/images/login-slider.jpg'
                            alt='login-slider'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
