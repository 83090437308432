import { Link } from "react-router-dom";

export default function Support() {
    return (
        <div className="support" data-aos="fade-up">
            <div className="row">
                <div className="col">
                    <p>
                        Have a query? <br />
                        Send Us an Email
                    </p>
                </div>
                {/* <div className="col-auto">
                    <a
                        href="tel:"
                        className="btn btn-primary rounded-circle shadow text-white"
                    >
                        <i className="fa fa-phone" />
                    </a>
                </div> */}
                <div className="col-auto">
                    <Link
                        to="/contact-us"
                        className="btn btn-primary rounded-circle shadow text-white"
                    >
                        <i className="fa fa-envelope" />
                    </Link>
                </div>
            </div>
        </div>
    );
}
