import { makeGenre, moveKeyToFirst } from "../../helpers/helper";
import Category from "./category";

export default function Categories({ products = [], setPopupData = {} }) {
    const genres = makeGenre(moveKeyToFirst(products, "TrueCaller"));

    return genres.map((item, indx) => (
        <Category category={item} key={indx} setPopupData={setPopupData} />
    ));
}
