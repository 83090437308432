import { useEffect } from "react";
import Footer from "../components/layout/footer/footer";
import Header from "../components/layout/header/header";
import LoginForm from "../components/login/loginForm";
import LoginSlider from "../components/login/loginSlider";

export default function LoginPage () {

    useEffect(() => {
        window.scrollTo(0, 0);

        // loading bootstrap plugin
        const script2 = document.createElement('script');
        script2.src="/assets/js/bootstrap.bundle.min.js";
        script2.async = true;
        document.body.appendChild(script2);

        // loading slick plugin 
        const script3 = document.createElement('script');
        script3.src = "/assets/vendor/slick/slick.min.js";
        script3.async = true;
        document.body.appendChild(script3);

        //loading aos plugin
        const script4 = document.createElement('script');
        script4.src = "/assets/vendor/aos/aos.js";
        script4.async = true;
        document.body.appendChild(script4);
        // loading main js file
        const script1 = document.createElement('script');
        script1.src = "/assets/js/main.js";
        script1.async = true;
        document.body.appendChild(script1);


        return () => {
            document.body.removeChild(script1);
            document.body.removeChild(script2);
            document.body.removeChild(script3);
            document.body.removeChild(script4);
        };
    }, []);

    return (
        <div className="main-wrapper">
            <Header />
            <main className="main-area">
                <section className="login-page">
                    <div className="container">
                        <div className="login">
                            <div className="row">
                                <div className="col-xl-6 login-left">
                                    <LoginForm />
                                </div>
                                <LoginSlider />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
};