export default function CategoryCard({
    product = {},
    setPopupData = { setPopupData },
}) {
    // const {setPopupData, setProduct, popupData} = useProfile();

    // const handleBuyNow = useCallback(async() => { console.log('product: ', product);
    //     if(callingAPI) return;
    //     setCallingAPI(true);
    //     const response = await getPopupData(product.category_id);
    //     let {success, status_code} = response;
    //     if(success && status_code===200){
    //         setPopupData(response);
    //         setProduct(findHighligtedProduct(response.products));
    //     } else{

    //     }
    //     setCallingAPI(false);
    // });

    return (
        <div
            className={`col t-category text-center ${
                product.status != "Published" &&
                product.status != "Comming Soon" &&
                "outofstock"
            } ${product.status == "Comming Soon" && "coming-soon"}`}
            data-aos='fade-up'
            data-aos-duration={500}
            onClick={(e) => {
                e.preventDefault();
                setPopupData(product);
            }}
        >
            <a href=''>
                <figure
                    style={{
                        backgroundImage: `url(${
                            product?.product_id === 37
                                ? "/assets/images/truecaller-daily.png"
                                : product?.product_id === 38
                                ? "/assets/images/truecaller-weekly.png"
                                : product?.product_id === 39
                                ? "/assets/images/truecaller-monthly.jpg"
                                : product?.product_id === 40
                                ? "/assets/images/truecaller-half-yearly.png"
                                : product?.product_id === 41
                                ? "/assets/images/truecaller-yearly.png"
                                : product.thumbnail_url
                        })`,
                    }}
                >
                    <span className='badge' />
                </figure>
            </a>
            <a href=''>
                <h3>{product.product}</h3>
            </a>
            {product?.category_id !== 12 && <p>(Email Delivery)</p>}
            <h4 className='price'>{product.price} ৳</h4>
            <button
                type='button'
                data-bs-toggle='modal'
                data-bs-target='#staticBackdrop'
                className='btn btn-dark'
                // // disabled={callingAPI}
                onClick={(e) => {
                    e.preventDefault();
                    setPopupData(product);
                }}
            >
                Buy Now
            </button>
        </div>
    );
}
