import { Link } from "react-router-dom";

export default function SecurePaymentSuccess({
    selectedChanel = {},
    product = {},
}) {
    const queryParams = new URLSearchParams(window.location.search);
    const category = queryParams.get("category");
    // const status_code = new URLSearchParams(window.location.search).get('status_code');
    // const order_id = queryParams.get("order_id");
    // const customer_reference = queryParams.get("customerReference");
    // const consent_id = queryParams.get("consentId");

    // console.log('order_id, cus_ref, con_id: ', order_id, customer_reference, consent_id)

    // useEffect(() => {
    //     if(order_id && customer_reference && consent_id) {
    //         updateOrderHistory()
    //     }
    // }, [order_id, customer_reference, consent_id]);

    // const updateOrderHistory = async () => {
    //     await updateOrderHistoryAPI(order_id, customer_reference, consent_id);
    // };

    return (
        <div className='secure-payment success d-block'>
            <div className='pertner-logo px-3 pt-3'>
                <div className='d-flex justify-content-between align-items-center'>
                    <img
                        src={selectedChanel.thumbnail_url}
                        alt={selectedChanel.name}
                    />
                    <img
                        src='/assets/images/footer-logo.png'
                        alt='footer'
                        width={80}
                    />
                </div>
            </div>
            <div className='pay-body text-center'>
                <img src='/assets/images/check.png' alt />
                <h2>Payment is Successful</h2>
                {category === "truecaller" ? (
                    <p>
                        Congratulations! Your Truecaller premium service is
                        ready now. For details:{" "}
                        <Link
                            // href='https://tc.onelink.me/571208033/abwmjqdl'
                            to={"/profile"}
                            target='_blank'
                        >
                            click here
                        </Link>{" "}
                    </p>
                ) : (
                    <p>
                        You will receive the product code via email shortly.
                        Please check spam as well. If you don’t get in within 30
                        Minutes, Please Contact codes@hullor.io
                    </p>
                )}
            </div>
        </div>
    );
}
