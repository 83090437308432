
export default function SecurePaymentInProgress({selectedChanel = {}}) {
    return (
        <div className="secure-payment d-block">
            <div className="pertner-logo px-3 pt-3">
                <div className="d-flex justify-content-between align-items-center">
                    <img src={selectedChanel.thumbnail_url} alt="gp" />
                    <img src="assets/images/footer-logo.png" alt="footer" width={80} />
                </div>
            </div>
            <div className="pay-body text-center">
                <img src="assets/images/secure-pay.png" alt="secure-pay" />
                <h2>Hullor Secured Payment</h2>
                <div className="payment-loding">
                    <p>Processing...</p>
                    <div className="progress" />
                </div>
            </div>
        </div>
    );
}
