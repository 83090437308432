import { Fragment, useState } from "react";
import { toast } from "react-toastify";
import TruecallerOTP from "../../../components/modals/truecaller/truecaller.otp";
import FullPageLoader from "../../../components/partials/loader/full-page";
import {
    truecaller_daily_weekly,
    truecaller_monthly_yearly,
} from "../../../constants/truecaller.constants";
import { acquireChargeUrlNagad } from "../../../helpers/api.helper";
import { getTruecallerPackageId } from "../../../helpers/helper";
import TrueCallerFormModalBkash from "../../modals/truecaller/truecaller.form.bkash";

export default function NotPurchased({ msisdn, refetch, ref_1, ref_2 }) {
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [modal_2, setModal_2] = useState(false);
    const [phone, setPhone] = useState("");
    const [packName, setPackName] = useState(null);

    const toggle = () => {
        setModal(!modal);
        setLoading(false);
    };
    const toggle_2 = () => {
        setModal_2(!modal_2);
        setLoading(false);
    };

    const handleClick = async (pckg) => {
        // setLoading(true);
        setPackName(pckg);

        if (msisdn) {
            setLoading(true);
            const res = await acquireChargeUrlNagad({
                msisdn,
                product_id: getTruecallerPackageId(pckg),
                payment_channel_id: 1,
            });
            if (res.url && res.status) {
                window.location.href = res.url;
            } else {
                toast.error(res?.message || "Sorry! Something went wrong!");
            }
            setLoading(false);
        } else {
            setModal(true);
            // history.push(`/truecaller-form?package=${pckg === 'monthly' ? 'monthly' : pckg === 'weekly' ? 'weekly' : 'daily'}&reference=${mygp_without_reference}${msisdn ? '&msisdn=' + msisdn : ''}`)
        }
    };

    return (
        <Fragment>
            <div
                className='screen'
                style={{
                    backgroundImage:
                        ref_1 === "bkash"
                            ? "url(/assets/images/Truecaller_bKash.png)"
                            : "auto",
                }}
            >
                <div className='screen-row'>
                    <div className='header'>
                        <h2>Trust your communication with</h2>
                        <img
                            src='/assets/images/TruecallerPremium.png'
                            alt=''
                        />
                    </div>

                    <div className='body' style={{ paddingTop: "10px" }}>
                        <img
                            src='/assets/images/TrueCaller_Feature.png'
                            alt=''
                        />
                    </div>
                    <div className='footer'>
                        <ul>
                            {truecaller_monthly_yearly.map((item, indx) => (
                                <li
                                    key={indx}
                                    className='truecaller-nagad-pricing-card'
                                >
                                    <div
                                        onClick={() => handleClick(item?.slug)}
                                    >
                                        <img
                                            src={item?.src}
                                            alt={item?.title}
                                        />
                                    </div>
                                </li>
                            ))}
                        </ul>
                        {/* <p>* 15% VAT + 1% SC Applicable</p> */}
                    </div>
                    <div className='footer'>
                        <ul>
                            {truecaller_daily_weekly.map((item, indx) => (
                                <li key={indx}>
                                    <div
                                        className='truecaller-nagad-pricing-card-element'
                                        onClick={() => handleClick(item?.slug)}
                                    >
                                        <img
                                            src={item?.src}
                                            alt={item?.title}
                                        />
                                    </div>
                                </li>
                            ))}
                        </ul>
                        {/* <p>* 15% VAT + 1% SC Applicable</p> */}
                    </div>
                    <div
                        style={{
                            marginTop: "2px",
                            color: "white",
                            // cursor: "pointer",
                            textAlign: "center",
                        }}
                        // onClick={() => setCheckTokenized(true)}
                    >
                        {/* <span>✅ Check your token eligibility</span>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            height='16'
                            width='14'
                            viewBox='0 0 448 512'
                        >
                            <path
                                fill='#e4e5e7'
                                d='M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z'
                            />
                        </svg> */}
                        <p style={{ fontSize: "x-small" }}>
                            NB: If there is any valid claim of refund by any
                            registered user, Momagic will arrange it with no
                            further delay within 7 to 10 working days. Refund
                            claims will be judged as per the governing law of
                            Bangladesh and the consumer rights law of
                            Bangladesh. For any complain, user may call:
                            09611116303 Or send an email with details at
                            supports@momagicbd.com
                        </p>
                    </div>
                </div>
            </div>
            {loading ? <FullPageLoader /> : ""}
            {modal && (
                <TrueCallerFormModalBkash
                    modal={modal}
                    toggle={toggle}
                    setLoading={setLoading}
                    setMsisdn={setPhone}
                    setModal_2={setModal_2}
                    package_name={packName}
                    reference={ref_2}
                />
            )}
            {modal_2 && (
                <TruecallerOTP
                    modal={modal_2}
                    toggle={toggle_2}
                    setLoading={setLoading}
                    msisdn={phone}
                    package_name={packName}
                    refetch={refetch}
                />
            )}
        </Fragment>
    );
}
