export default function ButtonSubmit({
    form = {},
    setForm = {},
    handleSubmit = {},
    disabled = false,
}) {
    return (
        <div className='action text-center mt-4 mb-2 d-none d-lg-block'>
            <button
                className='btn btn-primary'
                onClick={handleSubmit}
                disabled={disabled}
            >
                Buy
            </button>
            {/* <button className="btn btn-primary">
                <span
                    className="spinner-border"
                    style={{
                        width: "1rem",
                        height: "1rem",
                    }}
                    role="status"
                    aria-hidden="true"
                />
            </button> */}
        </div>
    );
}
