import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { updateOrderHistoryAPI } from "../../helpers/api.helper";

export default function Processing() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const status_code = new URLSearchParams(window.location.search).get(
        "status_code"
    );
    const order_id = queryParams.get("order_id");
    const customer_reference = queryParams.get("customerReference");
    const consent_id = queryParams.get("consentId");

    useEffect(() => {
        if (order_id && customer_reference && consent_id) {
            updateOrderHistory();
        }
    }, []);

    const updateOrderHistory = async () => {
        const res = await updateOrderHistoryAPI(
            order_id,
            customer_reference,
            consent_id
        );
        history.push(
            `/?status_code=${res?.success ? "200" : "500"}&category=truecaller`
        );
    };

    return (
        <div className='secure-payment d-block'>
            <div className='pertner-logo px-3 pt-3'>
                <div className='d-flex justify-content-between align-items-center'>
                    {/* <img src={'assets/images/hullor-logo.png'} alt="gp" /> */}
                    {/* <img src="assets/images/footer-logo.png" alt="footer" width={80} /> */}
                </div>
            </div>
            <div className='pay-body text-center'>
                <img src='assets/images/secure-pay.png' alt='secure-pay' />
                <h2>Secured Payment</h2>
                <div className='payment-loding'>
                    <p>Processing...</p>
                    <div className='progress' />
                </div>
            </div>
        </div>
    );
}
