import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../components/truecaller/style.css";
import { verifyTruecallerSubscription } from "../../helpers/api.helper";
import { moveCursor } from "../../helpers/helper";

export default function TruecallerOTPVerify() {
    const history = useHistory();
    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);
    const msisdn = searchParams.get("msisdn");
    const package_name = searchParams.get("package");
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState({
        d1: "",
        d2: "",
        d3: "",
        d4: "",
    });

    const handleOTPSubmit = async () => {
        setLoading(true);
        const response = await verifyTruecallerSubscription(
            msisdn,
            otp.d1 + otp.d2 + otp.d3 + otp.d4,
            package_name
        );
        setLoading(false);
        if (response.success) {
            history.push(response.redirect_url);
        } else {
            toast.error(
                typeof response?.message === "string"
                    ? response.message
                    : "Invalid OTP!"
            );
        }
    };

    return (
        <div className='p-2 truecaller d-flex vh-100 align-items-center justify-content-center'>
            <div className='step-1'>
                <div className='text-center'>
                    <img
                        src='/assets/images/true-sc-3.png'
                        alt=''
                        className='img-fluid'
                    />
                </div>
                <div className='pt-4 text-center'>
                    <h5>Verify Your Number</h5>
                    <p className='text-muted'>Enter OTP</p>
                </div>
                <div className='p-4 d-flex gap-2 justify-content-center'>
                    <div className='mb-0'>
                        <input
                            type='text'
                            className='form-control py-2 text-center'
                            id='o1'
                            value={otp.d1}
                            onChange={(e) =>
                                setOtp({ ...otp, d1: e.target.value })
                            }
                            maxLength={1}
                            onKeyUp={(e) => moveCursor(e, null, "o1", "o2")}
                        />
                    </div>
                    <div className='mb-0'>
                        <input
                            type='text'
                            className='form-control py-2 text-center'
                            id='o2'
                            value={otp.d2}
                            onChange={(e) =>
                                setOtp({ ...otp, d2: e.target.value })
                            }
                            maxLength={1}
                            onKeyUp={(e) => moveCursor(e, "o1", "o2", "03")}
                        />
                    </div>
                    <div className='mb-0'>
                        <input
                            type='text'
                            className='form-control py-2 text-center'
                            id='03'
                            value={otp.d3}
                            onChange={(e) =>
                                setOtp({ ...otp, d3: e.target.value })
                            }
                            maxLength={1}
                            onKeyUp={(e) => moveCursor(e, "o2", "03", "04")}
                        />
                    </div>
                    <div className='mb-0'>
                        <input
                            type='text'
                            className='form-control py-2 text-center'
                            id='04'
                            value={otp.d4}
                            onChange={(e) =>
                                setOtp({ ...otp, d4: e.target.value })
                            }
                            maxLength={1}
                            onKeyUp={(e) => moveCursor(e, "03", "04", null)}
                            onk
                        />
                    </div>
                </div>
                <div className='text-center pb-3'>
                    <button
                        className='submit-button btn-primary py-2 px-3'
                        onClick={handleOTPSubmit}
                        disabled={loading}
                    >
                        {loading && (
                            <i
                                className='fa fa-refresh fa-spin'
                                style={{ marginRight: "5px" }}
                            />
                        )}
                        {loading && <span>Loading...</span>}
                        {!loading && (
                            <span style={{ color: "white" }}>Next</span>
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
}
