import { useEffect, useRef, useState } from "react";
import CategoryCard from "./category-card";

export default function Category({ category = {}, setPopupData = {} }) {
    // console.log("category: ", category);
    const [shouldScroll, setShouldScroll] = useState(false);
    const divRef = useRef(null);
    const goto = window.location.hash.substring(1);

    useEffect(() => {
        // console.log("div ref: ", divRef);
        if (
            divRef &&
            divRef.current &&
            divRef.current.id === "truecaller" &&
            goto === "truecaller"
        ) {
            divRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
        }
    }, [shouldScroll]);

    useEffect(() => {
        // Simulating dynamic content fetch or update
        setTimeout(() => {
            setShouldScroll(true);
        }, 1000);
    }, []);

    return (
        <section className='categories pb-4' ref={divRef} id={category.slug}>
            <div
                className='s-head bg-dark text-white text-center py-4 my-3'
                data-aos='fade-up'
            >
                <h2 className='m-0'>{category.title}</h2>
            </div>
            <div className='container'>
                <div className='t-categories'>
                    <div
                        className='categories-slider row row-cols-2 row-cols-md-3 row-cols-lg-4'
                        data-aos='fade-up'
                        data-aos-duration='1000'
                    >
                        {category?.title === "TrueCaller"
                            ? category?.items
                                  ?.sort((a, b) => b.product_id - a.product_id)
                                  .map((item, indx) => (
                                      <CategoryCard
                                          product={item}
                                          key={indx}
                                          setPopupData={setPopupData}
                                      />
                                  ))
                            : category?.items?.map((item, indx) => (
                                  <CategoryCard
                                      product={item}
                                      key={indx}
                                      setPopupData={setPopupData}
                                  />
                              ))}
                    </div>
                </div>
            </div>
        </section>
    );
}
