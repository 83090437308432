export default function CardItem({ product = {} }) {
    // console.log("card item product: ", product);
    return product ? (
        <div className='col-lg-3 d-lg-block'>
            <div className='category-item'>
                <div className='t-category text-center'>
                    <figure
                        style={{
                            backgroundImage: `url(${
                                product?.product_id === 37
                                    ? "/assets/images/truecaller-daily.png"
                                    : product?.product_id === 38
                                    ? "/assets/images/truecaller-weekly.png"
                                    : product?.product_id === 39
                                    ? "/assets/images/truecaller-monthly.jpg"
                                    : product?.product_id === 40
                                    ? "/assets/images/truecaller-half-yearly.png"
                                    : product?.product_id === 41
                                    ? "/assets/images/truecaller-yearly.png"
                                    : product.thumbnail_url
                            })`,
                        }}
                    >
                        <span className='badge' />
                    </figure>
                    <h3>{product.product}</h3>
                    {product?.category_id !== 12 && <p>(Email Delivery)</p>}
                    <h4 className='price'>{product.price} ৳</h4>
                    <p>[The price is excluding VAT, SD and SC.]</p>
                </div>
            </div>
        </div>
    ) : (
        "No Products Found"
    );
}
