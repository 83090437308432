
import { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { verifyTruecallerSubscription } from '../../../helpers/api.helper';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { moveCursor } from '../../../helpers/helper';

export default function TruecallerOTP({
    modal,
    toggle,
    setLoading,
    msisdn,
    package_name,
    refetch
}) {
    const history = useHistory();
    const [otp, setOtp] = useState({
        d1: '', d2: '', d3: '', d4: ''
    });

    const handleOTPSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const response = await verifyTruecallerSubscription(msisdn, otp.d1 + otp.d2 + otp.d3 + otp.d4, package_name);
        setLoading(false);
        setOtp({
            d1: '', d2: '', d3: '', d4: ''
        });
        if (response.success) {
            history.push(response.redirect_url);
            refetch();
        } else {
            toast.error((typeof response?.message === 'string') ? response.message : 'Invalid OTP!');
        }
    }


    return (
        <Modal isOpen={modal} toggle={toggle} centered className='truecaller-modal'>
            <ModalBody className=" text-center p-4 pb-0">
                <div className="otp-box">
                    <div className="unsubscribe-body w-100 ">
                        <h2 className="text-black mb-0 pb-0">Verification code</h2>
                        <form action="#" className="caller-form w-100">
                            <label htmlFor="" className="form-label">
                                We have sent the code <br /> verification to your Contact
                                <br />
                                Number <strong>{msisdn}</strong>
                            </label>
                            <div className="d-flex align-items-center justify-content-center gap-3 mb-3 pt-2 otp">
                                <div>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="o1"
                                        value={otp.d1}
                                        onChange={(e) => setOtp({ ...otp, d1: e.target.value })}
                                        maxLength={1}
                                        onKeyUp={(e) => moveCursor(e, null, "o1", "o2")}
                                    />
                                </div>
                                <div>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="o2"
                                        value={otp.d2}
                                        onChange={(e) => setOtp({ ...otp, d2: e.target.value })}
                                        maxLength={1}
                                        onKeyUp={(e) => moveCursor(e, "o1", "o2", "03")}
                                    />
                                </div>
                                <div>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="03"
                                        value={otp.d3}
                                        onChange={(e) => setOtp({ ...otp, d3: e.target.value })}
                                        maxLength={1}
                                        onKeyUp={(e) => moveCursor(e, "o2", "03", "04")}
                                    />
                                </div>
                                <div>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="04"
                                        value={otp.d4}
                                        onChange={(e) => setOtp({ ...otp, d4: e.target.value })}
                                        maxLength={1}
                                        onKeyUp={(e) => moveCursor(e, "03", "04", null)}
                                    />
                                </div>
                            </div>
                            {/* <p className="mb-3">Resend code in 32s</p> */}
                            <button
                                className="btn btn-blue w-100"
                                onClick={handleOTPSubmit}
                                disabled={otp?.d1?.length === 0 || otp?.d2?.length === 0 || otp?.d3?.length === 0 || otp?.d4?.length === 0}
                            >
                                Continue
                            </button>
                        </form>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};