import { useState } from "react";
import { toast } from "react-toastify";
import { Modal, ModalBody } from "reactstrap";
import { truecaller_nagad_packages } from "../../../constants/truecaller.constants";
import { acquireChargeUrlBkashRecurring } from "../../../helpers/api.helper";

export default function TrueCallerFormModalBkash({
    modal,
    toggle,
    setLoading,
    setMsisdn,
    setModal_2,
    package_name,
    reference,
}) {
    const [phone, setPhone] = useState("");
    const [fetching, setFetching] = useState(false);

    const handleSubmitForm = async (e) => {
        e.preventDefault();
        setLoading(true);
        setFetching(true);
        setMsisdn(phone);

        const pack = truecaller_nagad_packages.filter(
            (e) => e?.slug === package_name
        )[0];

        const res = await acquireChargeUrlBkashRecurring({
            msisdn: phone,
            product_id: pack?.id || 0,
            payment_channel_id: 1,
        });

        if (res?.redirect_url && res?.success) {
            window.location.href = res.redirect_url;
        } else {
            setLoading(false);
            setFetching(false);
            if (res?.status_code == 4030) {
                toggle();
                setModal_2(true);
            } else {
                toast.error(res?.message || "Sorry! Something went wrong!");
            }
        }
    };

    return (
        <Modal
            isOpen={modal}
            toggle={toggle}
            centered
            className='truecaller-modal'
        >
            <ModalBody className=' text-center p-4 pb-0'>
                <div className='number-box d-block'>
                    <img
                        className='m-screen'
                        src='/assets/images/truecaller-logo-1.png'
                        alt=''
                    />
                    <div className='unsubscribe-body w-100 mt-3'>
                        <h2 className='text-black'>For Subscription</h2>
                        <form action='#' className='caller-form w-100'>
                            <label
                                for=''
                                className='form-label text-start d-block'
                            >
                                Mobile Number
                            </label>
                            <div className='input-group mb-3'>
                                <span className='input-group-text bg-white'>
                                    +88
                                </span>
                                <input
                                    type='number'
                                    className='form-control py-2 placeholder-color'
                                    placeholder='017 XXXX XXXX'
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </div>
                            <span></span>
                            <button
                                className='btn btn-blue w-100'
                                disabled={phone?.length !== 11 || fetching}
                                onClick={handleSubmitForm}
                            >
                                {`${fetching ? "Processing" : "Next"}`}
                            </button>
                        </form>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
}
