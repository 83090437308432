
export default function ProfileUpdateForm({user=""}) {
    return (
        <div className="update-info" data-aos="fade-up">
            <form action="#">
                {/* <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                        Email
                    </label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter your email"
                        defaultValue="foridpathan45@gmail.com"
                    />
                </div> */}
                <div className="mb-3">
                    <label htmlFor="number" className="form-label">
                        Mobile Number
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="number"
                        placeholder="Enter your mobile number"
                        value={user}
                    />
                </div>
                {/* <div className="form-action">
                    <button
                        className="btn btn-primary rounded-circle shadow text-white"
                        type="submit"
                    >
                        Update
                    </button>
                    <button
                        className="btn btn-primary rounded-circle shadow text-white"
                        type="submit"
                    >
                        <span
                            className="spinner-border"
                            role="status"
                            aria-hidden="true"
                        />
                    </button>
                </div> */}
            </form>
        </div>
    );
}
