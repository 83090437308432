import { useEffect, useState } from "react"
import { prepareConsentAPI } from "../../helpers/api.helper";
import { useHistory, useLocation } from "react-router-dom";
import { validatePhone } from "../../helpers/helper";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "../../components/truecaller/style.css";
import './submit-button.css'

export default function TrueCallerForm() {
    const history = useHistory();
    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);
    const token = searchParams.get('token');
    const package_name = searchParams.get('package');
    const reference = searchParams.get('reference');
    const [loading, setLoading] = useState(false);
    const [msisdn, setMsisdn] = useState(null);

    const handleSubmitForm = async () => {
        if(validatePhone(msisdn)) {
            setLoading(true);
            const res = await prepareConsentAPI(12, 8, package_name === 'monthly' ? 39 : package_name === 'weekly' ? 38 : 37, null, msisdn, {}, reference);
            setLoading(false);
            if (res.redirect && res.success) {
                window.location.href = res.charge_redirect_url;
                // history.push(response.charge_redirect_url);
            } else {
                if(res?.status_code == 4030) {
                    history.push(`/otp-verify/${msisdn? "?msisdn=" + msisdn : ''}${msisdn ? "&package=" : "?package="}${package_name && package_name}`);
                } else {
                    toast.error(res?.message || "Sorry! Somethind went wrong!")
                }
            }
        } else {
            toast.error("Invalid MSISDN!")
        }
        
    }
    return (
        <div className="p-2 truecaller d-flex vh-100 align-items-center justify-content-center">
            <div className="step-1">
                <div className="text-center">
                    <img src="/assets/images/true-sc-2.png" alt="" className="img-fluid" />
                </div>
                <div className="pt-4 text-center">
                    <h5>
                        Share Your <br />
                        Grameenphone Number:
                    </h5>
                </div>
                <div className="p-4 d-flex gap-2 justify-content-center">
                    <div className="input-group mb-3">
                        <span className="input-group-text bg-white" id="basic-addon1">
                            +88
                        </span>
                        <input
                            type="text"
                            className="form-control py-2 placeholder-color"
                            placeholder="017 XXXX XXXX"
                            onChange={(e) => setMsisdn(e.target.value)}
                        />
                    </div>
                </div>
                <div className="text-center pb-3">
                    <button className="submit-button btn-primary py-2 px-3" onClick={handleSubmitForm} disabled={loading}>
                        {loading && (
                            <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" }}
                            />
                        )}
                        {loading && <span>Loading...</span>}
                        {!loading && <span style={{color: "white"}}>Next</span>}
                    </button>
                </div>
            </div>
        </div>
    )
}