import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal, ModalBody } from "reactstrap";
import SecurePaymentFailed from "../../../components/modals/payment/securePaymentFailed";
import SecurePaymentSuccess from "../../../components/modals/payment/securePaymentSuccess";
import FullPageLoader from "../../../components/partials/loader/full-page";
import NotPurchased from "../../../components/truecaller/bkash/notPurchased";
import "../../../components/truecaller/style.css";
import {
    getLastOrders,
    unsubscribeTruecaller,
    updateOrderStatus,
} from "../../../helpers/api.helper";
import "../style/campaign-styles.css";

export default function TruecallerBkash({ reference }) {
    const history = useHistory();
    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);
    const msisdn = searchParams.get("msisdn");
    const status_code = searchParams.get("status_code");

    const package_name = searchParams.get("package");
    const order_param = searchParams.get("order");
    const status_param = searchParams.get("status");
    const [loading, setLoading] = useState(false);
    const [gettingOrder, setGettingOrder] = useState(false);
    const [purchasedAt, setPurchasedAt] = useState(null);
    const [hasPurchased, setHasPurchased] = useState(false);
    const [hasUnsubscribed, setUnsubscribed] = useState(false);
    const [order, setOrder] = useState(null);
    const [fetch, setFetch] = useState(true);
    const [showModal, setShowModal] = useState(
        // true
        ["200", "500"].includes(status_code)
    );

    const toggle = () => {
        setShowModal(!showModal);
        history.push(window.location.pathname);
    };

    useEffect(() => {
        if (!fetch) return;
        if (msisdn) {
            getLastOrder(msisdn);
        }
        setFetch(false);
    }, [fetch]);

    useEffect(() => {
        if (order_param && status_param) {
            updateStatus();
        }
    }, [order_param, status_param]);

    const updateStatus = async () => {
        const res = await updateOrderStatus(order_param, status_param);
    };

    const refetch = () => setFetch(true);

    const getLastOrder = async (msisdn) => {
        setGettingOrder(true);
        const res_last_order = await getLastOrders(msisdn);
        setGettingOrder(false);
        if (
            res_last_order?.success &&
            Array.isArray(res_last_order?.data) &&
            res_last_order?.data?.length > 0
        ) {
            const last_order = res_last_order?.data[0];
            setOrder(last_order);
            setHasPurchased(true);
            setPurchasedAt(
                moment(last_order?.last_renew_attempt_at).format(
                    "ddd MMM DD YYYY HH:mm:ss [GMT] ZZ (zz)"
                )
            );
            setUnsubscribed(last_order?.auto_renew ? false : true);
        }
    };

    const getPackageValidity = (pckg) => {
        if (pckg === "daily") {
            return 1;
        } else if (pckg === "weekly") {
            return 7;
        } else if (pckg === "monthly") {
            return 30;
        } else if (pckg === "half-yearly") {
            return 30 * 6;
        } else if (pckg === "yearly") {
            return 365;
        } else {
            return 0;
        }
    };

    const validTill = (time) => {
        const product_id =
            typeof order === "object" && order?.product_id
                ? order?.product_id
                : null;

        const package_order = product_id
            ? product_id === 37
                ? "daily"
                : product_id === 38
                ? "weekly"
                : product_id === 39
                ? "monthly"
                : product_id === 40
                ? "half-yearly"
                : "yearly"
            : null;

        const dateString = time;

        // Convert the date string to a Moment object
        const initialDate = moment(dateString, "ddd MMM DD YYYY HH:mm:ss ZZ");

        // Add 30 days
        const thirtyDaysLater = moment(initialDate)
            .add(getPackageValidity(package_order || package_name), "days")
            .format("MMMM Do YYYY, h:mm:ss a");

        return thirtyDaysLater;
    };

    const handleUnsubscribe = async () => {
        const product_id =
            typeof order === "object" && order?.product_id
                ? order?.product_id
                : null;

        setLoading(true);
        const res = await unsubscribeTruecaller(
            msisdn,
            product_id
                ? product_id
                : package_name === "yearly"
                ? 41
                : package_name === "half-yearly"
                ? 40
                : package_name === "monthly"
                ? 39
                : package_name === "weekly"
                ? 38
                : package_name === "daily"
                ? 37
                : null
        );
        setLoading(false);
        if (res?.success) {
            toast.success(res?.message);
            setUnsubscribed(true);
            if (res?.package && res?.package?.purchased_at) {
                const purchased_at = moment(res?.package?.purchased_at).format(
                    "ddd MMM DD YYYY HH:mm:ss [GMT] ZZ (zz)"
                );
                setPurchasedAt(purchased_at);
            }
        } else {
            toast.error(res?.message);
        }
    };

    if (gettingOrder)
        return (
            <div className='loading'>
                <div className='lds-facebook'>
                    <div />
                    <div />
                    <div />
                </div>
            </div>
        );

    return (
        <Fragment>
            {/* {hasPurchased ? (
                <Purchased
                    hasUnsubscribed={hasUnsubscribed}
                    validTill={validTill(purchasedAt)}
                    handleUnsubscribe={handleUnsubscribe}
                />
            ) : ( */}
            <NotPurchased
                msisdn={msisdn}
                refetch={refetch}
                ref_1={reference}
                ref_2={reference}
            />
            {/* )} */}
            {loading ? <FullPageLoader /> : ""}
            <Modal isOpen={showModal} centered toggle={toggle}>
                <div className='modal-content payments position-relative'>
                    {/* <button
                        type='button'
                        className='btn-close position-absolute top-0 end-0 d-block'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                        onClick={toggle}
                    /> */}
                    <ModalBody>
                        {status_code === "200" ? (
                            <SecurePaymentSuccess />
                        ) : (
                            <SecurePaymentFailed />
                        )}
                    </ModalBody>
                </div>
            </Modal>
        </Fragment>
    );
}
