import { useEffect } from "react";
import { useState } from "react";
import ReactLoading from "react-loading";
import { useLocation } from 'react-router-dom';
import { getCustomerInfoForMygp } from "../helpers/api.helper";

export default function MygpTrucallerSubscriptionPrepare() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);
    const token = searchParams.get('token');

    useEffect(() => {
        getCustomerInfo();
    }, []);

    const getCustomerInfo = async() => {
        const res = await getCustomerInfoForMygp(token);
        setLoading(false);
        if(res?.success) {
            setData(res?.data);
        }
    }
    
    if(loading) {
        return (
            <div style={{
                margin: '50px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap'
            }}>
                <ReactLoading type={'spokes'} color="black" />
                <div style={{
                    paddingTop: '10px'
                }}>
                    <h3>processing...</h3>
                </div>
            </div>
        )
    }

    return (
        <>
            {data ? (
                <div>
                    <div style={{
                        margin: '50px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <img src="assets/images/hullor-logo.png"/>
                        <h3>Truecaller Subscription </h3>
                        <span>Buy, Daily Pack(excluding VAT) <strong>৳ 5.00</strong></span>
                        <div style={{
                            marginTop: '5px',
                            marginBottom: '5px'
                        }}>
                            <img src="https://api.hullor.io/truecaller/truecaller.jpeg" height={350} />
                        </div>
                        <button
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                            className="btn btn-dark"
                            // // disabled={callingAPI}
                        >
                            Proceed
                        </button>
                    </div>
                </div>
            ) : (
                <h3 style={{textAlign: 'center'}}>No Data Found!</h3>
            )}
        </>
    )
}