import CryptoJS from "crypto-js";
import { createAxiosInstance } from "../components/DOB/Consent/api";

export function safeJSONParse(jsonString) {
    try {
        return JSON.parse(jsonString.replace(/\\/g, ""));
    } catch (error) {
        console.error("Error parsing JSON:", error.message);
        return {};
    }
}

function safeJSONStringify(obj) {
    try {
        return JSON.stringify(obj);
    } catch (error) {
        console.error("Error stringify JSON:", error.message);
        return ""; // or handle the error in a way that makes sense for your application
    }
}

export function encryptObject(obj, key) {
    const jsonString = safeJSONStringify(obj);
    const encrypted = CryptoJS.AES.encrypt(jsonString, key).toString();
    return encodeURIComponent(encrypted); // Use standard Base64 encoding
}

// Decrypt an object using AES decryption
export function decryptObject(encryptedString, key) {
    const decoded = decodeURIComponent(encryptedString); // Decode from standard Base64
    const decrypted = CryptoJS.AES.decrypt(decoded, key).toString(
        CryptoJS.enc.Utf8
    );
    return safeJSONParse(decrypted);
}

// my axios calling function

export async function banglalinkCheckPaymentStatus(api_url) {
    const apiUrl = api_url; // Dynamic URL received from another component
    const requestOptions = {
        method: "GET",
    };

    // Initialize the retry count for this request
    requestOptions.retryCount = 0;

    const dynamicAxiosInstance = createAxiosInstance();

    const retryDelay = 5000; // 5 seconds delay

    let lastResponse = null;

    try {
        while (requestOptions.retryCount < 5) {
            const response = await dynamicAxiosInstance(apiUrl, requestOptions);

            if (response.status === 200) {
                // Handle successful response
                console.log("API call succeeded:", response);
                return response.data;
            } else {
                // Handle non-200 status codes
                console.error(
                    `API call failed with status code ${response.status}`
                );
                console.log(response.data);
                lastResponse = response.data;
            }

            // Wait for the specified delay before the next retry
            await new Promise((resolve) => setTimeout(resolve, retryDelay));

            // Increment the retry count
            requestOptions.retryCount++;
        }

        // If max retry attempts reached, return the last response
        console.error(`API call failed after ${5} attempts`);
        return lastResponse; // or throw an error if you prefer
    } catch (error) {
        // Handle errors, including max retry attempts reached
        console.error("API call failed:", error.message);
        return { success: false, status_code: 403, message: error.message }; // Rethrow the error for further handling
    }
}
