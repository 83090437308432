import { Fragment } from "react/cjs/react.production.min";
import Header from "../components/layout/header/header";
import MainSlider from '../components/home/slider/mainSlider';
import PayIntroduction from "../components/home/payInroduction";
import NavBar from "../components/home/navbar";
import Categories from "../components/home/categories";
import Footer from "../components/layout/footer/footer";
import { useEffect, useRef, useState } from "react";
import Payment from "../components/modals/payment";
import { fetchData } from "../helpers/api.helper";
import { useScrollData } from "scroll-data-hook";

export default function HomePage() {
    const {
        scrolling,
        time,
        speed,
        direction,
        position,
        relativeDistance,
        totalDistance
      } = useScrollData({
        // onScrollStart: () => {
        //   console.log('Started scrolling');
        // },
        // onScrollEnd: () => {
        //   console.log('Finished scrolling');
        // }
      });
    const [data, setData] = useState(null);
    // const {popupData, product} = useProfile(); console.log('pop up data homepage: ', popupData, product);
    const [popupData, setPopupData] = useState(null); console.log('pop up data homepage: ', popupData)
    const isCancelled = useRef(false);
    const queryParams = new URLSearchParams(window.location.search); 
    const status_code = new URLSearchParams(window.location.search).get('status_code'); console.log('status code: ', status_code);
    const status = new URLSearchParams(window.location.search).get('status'); console.log('status: ', status === 'processing')

    useEffect(() => {
        window.scrollTo(0, 0);
        // loading jquery file
        const script5 = document.createElement('script');
        script5.src = "/assets/js/jquery.min.js";
        script5.async = true;
        document.body.appendChild(script5);

        // loading bootstrap plugin
        const script2 = document.createElement('script');
        script2.src="/assets/js/bootstrap.bundle.min.js";
        script2.async = true;
        document.body.appendChild(script2);

        // loading slick plugin 
        const script3 = document.createElement('script');
        script3.src = "/assets/vendor/slick/slick.min.js";
        script3.async = true;
        document.body.appendChild(script3);

        //loading aos plugin
        const script4 = document.createElement('script');
        script4.src = "/assets/vendor/aos/aos.js";
        script4.async = true;
        document.body.appendChild(script4);

        // loading main js file
        const script1 = document.createElement('script');
        script1.src = "/assets/js/main.js";
        script1.async = true;
        document.body.appendChild(script1);


        return () => {
            document.body.removeChild(script5);
            document.body.removeChild(script2);
            document.body.removeChild(script3);
            document.body.removeChild(script4);
            document.body.removeChild(script1);
            
        };
    }, [data]);
    useEffect(() => {
        if(status_code == 200 || status_code == 500) {
            setPopupData({});
        }
    }, [status_code]);
    
    useEffect(() => {
        getHomePageContent();

        return () => {
            isCancelled.current = true;
        };
    }, [status_code]);
    const getHomePageContent = async () => {
        const responseData = await fetchData();
        if(!isCancelled.current) setData(responseData);
    };
    // if(loading) {
    //     setData(getHomePageContent());
    // }
    // const getHomePageContent = async() => {
    //     const response = await fetchData();
    //     return response;
    // }
    
    if(!data) return (
        <div className="loading">
            <div className="lds-facebook">
                <div />
                <div />
                <div />
            </div>
        </div>
    );
    

    return (
        <Fragment>
            { data.error ? (<div className="error-message py-4 text-center position-absolute">
                <h1>{data.error}</h1>
            </div>) :
            (<div className="main-wrapper">
                <Header />
                <div className="main-area">
                    <MainSlider />
                    <PayIntroduction />
                    <NavBar products={data.products}/>
                    <Categories products={data.products} setPopupData={setPopupData}/>
                </div>
                <Footer />
            </div>)}
            {popupData &&  <Payment product={popupData} setProduct={setPopupData}/>}
            {window.pageYOffset > 280 && (
                <div className="uparrow show" onClick={() => window.scrollTo(0, 0)}>
                    <button className="btn btn-primary arrow">
                        <i className="fa fa-angle-up"></i>
                    </button>
                </div>
            )}
            
        </Fragment>
    );
};