import { useEffect } from "react";
import Footer from "../components/layout/footer/footer";
import Header from "../components/layout/header/header";

export default function TermsAndConditions() {
    useEffect(() => {
        window.scrollTo(0, 0);

        // loading bootstrap plugin
        const script2 = document.createElement("script");
        script2.src = "/assets/js/bootstrap.bundle.min.js";
        script2.async = true;
        document.body.appendChild(script2);

        // loading slick plugin
        const script3 = document.createElement("script");
        script3.src = "/assets/vendor/slick/slick.min.js";
        script3.async = true;
        document.body.appendChild(script3);

        //loading aos plugin
        const script4 = document.createElement("script");
        script4.src = "/assets/vendor/aos/aos.js";
        script4.async = true;
        document.body.appendChild(script4);

        // loading main js file
        const script1 = document.createElement("script");
        script1.src = "/assets/js/main.js";
        script1.async = true;
        document.body.appendChild(script1);

        return () => {
            document.body.removeChild(script1);
            document.body.removeChild(script2);
            document.body.removeChild(script3);
            document.body.removeChild(script4);
        };
    }, []);
    return (
        <div className='main-wrapper'>
            <Header />
            <main className='main-area'>
                <section className=''>
                    <div className='container'>
                        <div className='contact py-5'>
                            <div className='text-center'>
                                <strong>Terms & Conditions</strong>
                            </div>
                            <div>
                                <span>
                                    You understand and acknowledge that all
                                    copyright, service marks, trademarks, trade
                                    and intellectual property names of Momagic
                                    Bangladesh limited (“Momagic”), including
                                    but not limited to Momagic, power tools, ask
                                    star, are the exclusive property of Momagic,
                                    and you shall not use any of the marks
                                    without Momagic’s prior written approval.
                                    Table of Contens.
                                </span>
                                <br />
                                <strong>Table of Contents</strong>
                                <ul>
                                    <li>
                                        <a href='#basic-information'>
                                            Basic Information
                                        </a>
                                    </li>
                                    <li>
                                        <a href='#service-purchase-policy'>
                                            Service Purchase Policy
                                        </a>
                                    </li>
                                    <li>
                                        <a href='#refund-policy'>
                                            Refund Policy
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div id='contents'>
                                <div id='basic-information'>
                                    <strong>Basic Information</strong>
                                    <div>
                                        <p>
                                            You understand and acknowledge that
                                            all copyright, service marks,
                                            trademarks, trade and intellectual
                                            property names of Momagic Bangladesh
                                            limited (“Momagic”), including but
                                            not limited to Momagic, power tools,
                                            ask star, are the exclusive property
                                            of Momagic, and you shall not use
                                            any of the marks without Momagic’s
                                            prior written approval.
                                        </p>
                                        <p>
                                            The content of this website is
                                            produced by Momagic, or under its
                                            instructions all intellectual
                                            property rights in respect of the
                                            content vest with Momagic, you may
                                            not therefore copy or reproduce the
                                            same without Momagic’s prior written
                                            permission.
                                        </p>
                                        <p>
                                            Certain information and/ services
                                            mentioned herein may not be
                                            accessible to you if your pc does
                                            not support the relevant technology.
                                        </p>
                                        <p>
                                            We discourage to provide any
                                            confidential /proprietary
                                            information. but in some areas of
                                            Momagic, we may need your
                                            information to fulfill the service
                                            requested. In such cases, any
                                            information of the visitors / users
                                            of the portal will be treated in
                                            accordance with Momagic privacy
                                            policy.
                                        </p>
                                        <p>
                                            These terms and condition shall be
                                            governed and interpreted in
                                            accordance with the laws of
                                            Bangladesh and you consent to the
                                            non-exclusive jurisdiction of
                                            Bangladesh courts.
                                        </p>
                                    </div>
                                </div>
                                <div id='service-purchase-policy'>
                                    <strong>Service Purchase Policy</strong>
                                    <div>
                                        <p>
                                            <b>Purchase/Subscribe: </b>To use
                                            the services, users’ need to visit -
                                            https://hullor.io/, users will
                                            choose their desired service &
                                            package. Then they will be
                                            redirected for payment. For payment
                                            through mobile wallet, users will be
                                            asked to provide their mobile
                                            number, and then an OTP will be sent
                                            for verification. Once verification
                                            is complete, users will be charged.
                                            After successful charging, users
                                            will be able to avail the services.
                                            For availing the services with other
                                            wallets like MFS or SSL gateway,
                                            users need to provide their contact
                                            number for which they would like to
                                            avail the services, then they’ll be
                                            redirected to the associated payment
                                            page
                                        </p>
                                        <p>
                                            <b>Unsubscribe: </b>Users can also
                                            unsubscribe/deregister from the
                                            services by logging into the portal
                                            https://hullor.io/login by simply
                                            providing the phone number users
                                            used to register for the service,
                                            after OTP verification they’ll be
                                            redirected to the Purchase history
                                            page. From there they can simply
                                            turn off the auto-renew.
                                        </p>
                                    </div>
                                </div>
                                <div id='refund-policy'>
                                    <strong>Refund Policy</strong>
                                    <div>
                                        <p>
                                            If there is any valid claim of
                                            refund by any registered user,
                                            Momagic will arrange it with no
                                            further delay. Refund claims will be
                                            judged as per the governing law of
                                            Bangladesh and the consumer rights
                                            law of Bangladesh.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}
