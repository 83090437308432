import {
    Route,
    Redirect
  } from 'react-router-dom';
import { useProfile } from '../contexts/profile/use-profile';
  
  function PrivateRoute({ component: Component, ...rest }) { 
    const { user } = useProfile();
    const isAuthenticated = (user != null) || (localStorage.getItem('user_msisdn') != null);
    return (
      <Route
        {...rest}
        render={(props) => {
          return isAuthenticated ? (
            <Component {...props}/> 
          ) : (
            <Redirect to={"/login"} />
          );
        }}
      />
    );
  }
  
  export default PrivateRoute;