export default function Price({ price = "" }) {
    console.log("price: ", price);
    return (
        <div className='prices pb-4 d-none d-lg-block'>
            <div className='title text-center'>
                <h2>Price</h2>
            </div>
            <div className='payable-amounts text-center'>
                <span>৳ {price}</span>
            </div>
        </div>
    );
}
