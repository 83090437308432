import { BASE_URL } from "../config";

export const API_BASE_URL = `${BASE_URL}/api/v2/`;
export const GET_ACCESS_TOKEN = `${API_BASE_URL}get_access_token`;
export const HOME_PAGE_CONTENT = `${API_BASE_URL}home_page_content`;
export const SHOW_CATEGORY_WISE_PRODUCT_URL = `${API_BASE_URL}category_wise_product_show`;
export const CHECKOUT_URL = `${API_BASE_URL}order/submit`;
export const PREPARE_CONSENT_API = `${API_BASE_URL}prepare-consent`;
export const LAST_ORDER_URL = `${API_BASE_URL}truecaller/last-order`;
export const ORDER_STATUS_UPDATE = `${API_BASE_URL}truecaller/update-status`;
export const UPDATE_ORDER_HISTORY = `${API_BASE_URL}update-order-history`;
export const GP_OTP_CHARGING_URL = `${API_BASE_URL}order/otp_charging`;
export const USER_PURCHASE_HISTORY_URL = `${API_BASE_URL}get_user_purchase_history`;
export const CONTACT_FORM_SUBMIT_URL = `${API_BASE_URL}contact_us`;
export const LOGIN_URL = `${API_BASE_URL}login`;
export const OTP_VERIFY_URL = `${API_BASE_URL}otp_verify`;
export const CONSENT_CHARGING_API = `${API_BASE_URL}order/request`;
export const TOGGLE_AUTO_RENEW = `${API_BASE_URL}package_renew`;
export const MYGP_CUSTOMER_INFO = `${API_BASE_URL}mygp/get-customer-info`;
export const TRUECALLER_UNSUBSCRIBE = `${API_BASE_URL}truecaller/deregister`;
export const VERIFY_TRUECALLER_SUBSCRIPTION = `${API_BASE_URL}truecaller/verify-user`;
export const ACQUIRE_CHARGE_URL_NAGAD =
    API_BASE_URL + "payment/acquire_charging-url";
export const BKASH_RECURRING_REDIRECT =
    API_BASE_URL + "payment/bkash-recurring/redirect";
export const NAGAD_ELIGIBILITY_CHECK =
    API_BASE_URL + "payment/nagad-eligibility-check";
export const NAGAD_ELIGIBILITY_CANCEL =
    API_BASE_URL + "payment/nagad-eligibility-cancel";
export const BL_SDP_CONSENT = API_BASE_URL + "bldob/consent";
export const BKASH_UNSUBSCRIBE = `${API_BASE_URL}payment/bkash-recurring/unsubscribe`;
export const ROBI_UNSUBSCRIBE = `${API_BASE_URL}payment/robi/unsubscribe`;
