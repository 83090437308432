
const cardTitles = [
    {
        title: 'User Friendly',
        image: "assets/images/drop.svg"
    },
    {
        title: 'Secure Payment',
        image: "assets/images/credit-card.svg"
    },
    {
        title: 'Instant Delivery',
        image: "assets/images/truck.svg"
    }
];

export default function PayIntroduction() {

    return (
        <section className="pay-intruction">
            <div className="container">
                <div className="payment-instruction row g-lg-2">
                    {cardTitles.map((item, indx) => (
                        <div className="col" key={indx}>
                            <div className="card text-center shadow ms-auto me-auto">
                                <div className="text-center">
                                    <div className="card-head">
                                        <div className="icon bg-primary bg-gradient">
                                            <img
                                                src={item.image}
                                                width={34}
                                                alt={item.title}
                                            />
                                        </div>
                                        <h3 className="mt-2">{item.title}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}
