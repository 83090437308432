import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useProfile } from "../../contexts/profile/use-profile";
import { callLogInAPI, verifyOTP } from "../../helpers/api.helper";
import { moveCursor, validatePhone } from "../../helpers/helper";

export default function LoginForm() {
    console.log("query selector: ", document.querySelector(".login-form"));
    const { addUser } = useProfile();
    const [phoneEnabled, setPhoneEnabled] = useState(true);
    const [phone, setPhone] = useState("");
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState({
        d1: "",
        d2: "",
        d3: "",
        d4: "",
    });
    const [err, setErr] = useState(null);
    const history = useHistory();

    const handlePhoneSubmit = () => {
        if (phoneEnabled) {
            const isPhoneValid = validatePhone(phone);
            if (isPhoneValid) {
                setErr(null);
                sendOTP();
            } else {
                setErr("Please Enter a Valid Phone no.");
            }
        } else {
            loginUser();
            setOtp({
                d1: "",
                d2: "",
                d3: "",
                d4: "",
            });
        }
    };

    const sendOTP = useCallback(async () => {
        setLoading(true);
        const response = await callLogInAPI(phone);
        setLoading(false);
        console.log("login response", response);
        if (response.success && response.status_code == 200) {
            setPhoneEnabled(false);
        } else if (response.error) {
            setErr(response.error);
        } else {
            setErr(response.message);
        }

        if (response.status_code == 6027) {
            setErr(response.message);
            setTimeout(() => {
                history.push("/");
            }, 5000);
        }
    });

    const loginUser = useCallback(async () => {
        setLoading(true);
        const response = await verifyOTP(
            phone,
            otp.d1 + otp.d2 + otp.d3 + otp.d4
        );
        setLoading(false);
        console.log("otp verify response: ", response);
        if (response.success && response.status_code == 200) {
            addUser(response.msisdn);
            localStorage.setItem("user_msisdn", response.msisdn);
            history.replace("/profile");
        } else if (response.error) {
            setErr(response.error);
        } else {
            console.log("error:", err);
            setErr(response.message);
        }

        if (response.status_code == 6030) {
            setTimeout(() => {
                setPhoneEnabled(true);
            }, 3000);
        }
    });
    console.log("error", err);

    return (
        <div className='login-form login-show'>
            <h4 data-aos='zoom-in-up' data-aos-duration={300}>
                Hello There! <br /> Start your Journey
            </h4>
            <h1 data-aos='fade-up'>Login</h1>
            <form action='#' data-aos='fade-right' data-aos-duration={1000}>
                <div className='l-form w-md-50'>
                    {phoneEnabled && (
                        <div className='mb-3'>
                            <label htmlFor='mobile' className='form-label'>
                                mobile Number
                            </label>
                            <input
                                type='text'
                                className='form-control'
                                id='mobile'
                                placeholder='Enter your mobile number'
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </div>
                    )}
                    {/* <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue
                            id="remember"
                        />
                        <label className="form-check-label" htmlFor="remember">
                            Remember me
                        </label>
                    </div> */}
                    {!phoneEnabled && (
                        <div className='mobile-verification w-50'>
                            <div className='clearfix'>
                                <p>
                                    We have sent you a 4 digit code to your
                                    phone, Please input below
                                </p>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <div className='mb-3'>
                                        <input
                                            id='otp-digit-1'
                                            type='text'
                                            className='form-control'
                                            value={otp.d1}
                                            onChange={(e) =>
                                                setOtp({
                                                    ...otp,
                                                    d1: e.target.value,
                                                })
                                            }
                                            maxLength={1}
                                            onKeyUp={(e) =>
                                                moveCursor(
                                                    e,
                                                    null,
                                                    "otp-digit-1",
                                                    "otp-digit-2"
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='mb-3'>
                                        <input
                                            id='otp-digit-2'
                                            type='text'
                                            className='form-control'
                                            value={otp.d2}
                                            onChange={(e) =>
                                                setOtp({
                                                    ...otp,
                                                    d2: e.target.value,
                                                })
                                            }
                                            maxLength={1}
                                            onKeyUp={(e) =>
                                                moveCursor(
                                                    e,
                                                    "otp-digit-1",
                                                    "otp-digit-2",
                                                    "otp-digit-3"
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='mb-3'>
                                        <input
                                            id='otp-digit-3'
                                            type='text'
                                            className='form-control'
                                            value={otp.d3}
                                            onChange={(e) =>
                                                setOtp({
                                                    ...otp,
                                                    d3: e.target.value,
                                                })
                                            }
                                            maxLength={1}
                                            onKeyUp={(e) =>
                                                moveCursor(
                                                    e,
                                                    "otp-digit-2",
                                                    "otp-digit-3",
                                                    "otp-digit-4"
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='mb-3'>
                                        <input
                                            id='otp-digit-4'
                                            type='text'
                                            className='form-control'
                                            value={otp.d4}
                                            onChange={(e) =>
                                                setOtp({
                                                    ...otp,
                                                    d4: e.target.value,
                                                })
                                            }
                                            maxLength={1}
                                            onKeyUp={(e) =>
                                                moveCursor(
                                                    e,
                                                    "otp-digit-3",
                                                    "otp-digit-4",
                                                    null
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {err && <div className='text-danger'>{err}</div>}
                    <div className='row form-action align-items-center'>
                        <div className='col-auto'>
                            {!loading && (
                                <button
                                    className='btn btn-primary d-flex align-items-center justify-content-center'
                                    type='submit'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handlePhoneSubmit();
                                    }}
                                >
                                    <i className='fa fa-arrow-right' />
                                    {/* <span class="spinner-border" role="status" aria-hidden="true"></span> */}
                                </button>
                            )}

                            {/* This is loader button */}
                            {loading && (
                                <button
                                    className='btn btn-primary d-flex align-items-center justify-content-center'
                                    type='submit'
                                >
                                    <span
                                        className='spinner-border'
                                        role='status'
                                        aria-hidden='true'
                                    />
                                </button>
                            )}
                        </div>
                        {/* <div className="col-auto">
                            <p>Or</p>
                        </div>
                        <div className="col-auto">
                            <button type="button" id="signup">
                                Signup
                            </button>
                        </div> */}
                    </div>
                </div>
            </form>
            <form action='#' className='pe-5'>
                <div className='r-form'>
                    {phoneEnabled && (
                        <div className='mb-3'>
                            <label htmlFor='mobile' className='form-label'>
                                mobile Number
                            </label>
                            <input
                                type='text'
                                className='form-control'
                                id='mobile'
                                placeholder='Enter your mobile number'
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </div>
                    )}
                    {!phoneEnabled && (
                        <div className='mobile-verification w-50'>
                            <div className='clearfix'>
                                <p>
                                    We have sent you a 4 digit code to your
                                    phone, Please input below
                                </p>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <div className='mb-3'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            value={otp.d1}
                                            onChange={(e) =>
                                                setOtp({
                                                    ...otp,
                                                    d1: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='mb-3'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            value={otp.d2}
                                            onChange={(e) =>
                                                setOtp({
                                                    ...otp,
                                                    d2: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='mb-3'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            value={otp.d3}
                                            onChange={(e) =>
                                                setOtp({
                                                    ...otp,
                                                    d3: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='mb-3'>
                                        <input
                                            type='text'
                                            className='form-control'
                                            value={otp.d4}
                                            onChange={(e) =>
                                                setOtp({
                                                    ...otp,
                                                    d4: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {err && <div className='text-danger'>{err}</div>}
                    <div className='row form-action align-items-center'>
                        <div className='col-auto'>
                            <button
                                className='btn btn-primary'
                                type='submit'
                                onClick={(e) => {
                                    e.preventDefault();
                                    handlePhoneSubmit();
                                }}
                            >
                                <i className='fa fa-arrow-right' />
                            </button>
                        </div>
                        {/* <div className="col-auto">
                            <p>Or</p>
                        </div>
                        <div className="col-auto">
                            <button type="button" id="login">
                                Login
                            </button>
                        </div> */}
                    </div>
                </div>
            </form>
        </div>
    );
}
