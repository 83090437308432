import { useState } from "react";
import { toast } from "react-toastify";
import { paymentChannels } from "../../config/paymentChannels";
import { useProfile } from "../../contexts/profile/use-profile";
import {
    cancelBkashSubscription,
    cancelRobiSubscription,
    toggleAutoRenew,
} from "../../helpers/api.helper";
import "./toggle-button.css";

const getPaymentChannelName = (id) => {
    const paymentChannel = paymentChannels.filter((e) => e.id === id)[0];
    if (!!paymentChannel) {
        return paymentChannel?.name;
    }
    return "";
};

export default function PurchaseHistory({ purchases = [] }) {
    const { user } = useProfile();
    const _msisdn = localStorage.getItem("user_msisdn");

    const prepareRenewItems = (purchases) => {
        let list = [];
        for (let i = 0; i < purchases.length; i++) {
            list.push(purchases[i].auto_renew);
        }
        return list;
    };
    const [renewItem, setRenewItem] = useState(prepareRenewItems(purchases));

    const toggleRenew = async (indx) => {
        const res =
            purchases[indx]?.payment_channel_id === 1
                ? await cancelBkashSubscription(
                      _msisdn,
                      purchases[indx]?.product_id
                  )
                : purchases[indx]?.payment_channel_id === 10
                ? await cancelRobiSubscription(purchases[indx]?.order_id)
                : await toggleAutoRenew(
                      purchases[indx]?.order_id,
                      user,
                      renewItem[indx] ? 0 : 1
                  );

        if (res?.success) {
            const list = [];
            for (let i = 0; i < renewItem.length; i++) {
                if (i === indx) {
                    list.push(renewItem[i] ? 0 : 1);
                } else {
                    list.push(renewItem[i]);
                }
            }
            setRenewItem(list);
            toast.success(res?.message || "User is unsubscribed successfully!");
        } else {
            toast.error(
                res?.message ||
                    res?.error ||
                    "Sorry! Could not change the auto renew status."
            );
        }
    };

    return (
        <div className='purchase-list text-center pb-5'>
            <h2>Purchase History</h2>
            <div
                className='plists shadow overflow-hidden'
                data-aos='fade-up'
                data-aos-duration={1200}
            >
                <table className='table table-bordered border-white table-striped table-hover mb-0'>
                    <thead className='table-dark'>
                        <tr>
                            <th>Date</th>
                            <th>Product</th>
                            <th>Payment Channel</th>
                            <th>Code</th>
                            <th>Auto Renew</th>
                        </tr>
                    </thead>
                    <tbody>
                        {purchases.map((item, indx) => (
                            <tr key={indx}>
                                <td>{item.purchase_date}</td>
                                <td>{item.name}</td>
                                <td>
                                    {getPaymentChannelName(
                                        item?.payment_channel_id
                                    )}
                                </td>
                                <td>
                                    {item?.category_id === 12
                                        ? "N/A"
                                        : item.code}
                                </td>
                                <td>
                                    {item?.category_id === 12 ? (
                                        <button
                                            class={
                                                renewItem[indx]
                                                    ? "toggle-button on"
                                                    : "toggle-button off"
                                            }
                                            on={item?.auto_renew}
                                            onClick={() => toggleRenew(indx)}
                                            disabled={
                                                item?.payment_channel_id ===
                                                    1 && item?.auto_renew === 0
                                            }
                                        >
                                            <span class='pin' />
                                        </button>
                                    ) : (
                                        "N/A"
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
