export default function Packages({
    packages = [],
    product = {},
    setProduct = {},
}) {
    console.log("packages: ", packages);
    // const {product, setProduct} = useProfile(); console.log('product: ', product);
    //alert(12)

    if (!packages || !packages.length) return <h1>No Product Found</h1>;

    return (
        <div className='packages'>
            <div className='title text-center'>
                <h2>Select Your Product</h2>
            </div>
            <div className='row justify-content-center row-cols-3 row-cols-lg-3 g-2'>
                {packages.map((item, indx) => (
                    <div
                        className='col'
                        key={indx}
                        onClick={() => setProduct(item)}
                    >
                        {/* <input
                            type="radio"
                            className="btn-check"
                            name="recharge-amount"
                            id={`rm-${indx.toString()}`}
                            autoComplete="off"
                            
                        /> */}
                        <label
                            className='btn btn-outline-primary'
                            htmlFor='rm-1'
                            style={
                                item.product == product.product
                                    ? {
                                          borderStyle: "solid",
                                          borderColor: "#2fb6e9",
                                          borderWidth: "2px",
                                      }
                                    : {}
                            }
                        >
                            {item.product}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
}
