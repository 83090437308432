import Processing from "../components/modals/processing";
import TruecallerDaily from "../components/truecaller/daily";
import AboutUs from "../pages/AboutUs";
import DobConsentPage from "../pages/Consentpage";
import ContactUs from "../pages/ContactUs";
import Homepage from "../pages/Homepage";
import LoginPage from "../pages/LoginPage";
import MygpTrucallerSubscriptionPrepare from "../pages/MygpTruecallerSubscriptionPrepare";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import RefundPolicy from "../pages/RefundPolicy";
import TermsAndConditions from "../pages/TermsAndConditions";
import Tournament from "../pages/tournament";
import TruecallerCampaigns from "../pages/truecaller/campaign";
import TrueCallerForm from "../pages/truecaller/truecaller-form";
import TruecallerLandingPage from "../pages/truecaller/truecaller-landing";
import TruecallerOTPVerify from "../pages/truecaller/truecaller-otp-verify";

export const publicRoutes = [
    {
        path: "/",
        component: Homepage,
    },
    {
        path: "/login",
        component: LoginPage,
    },
    {
        path: "/contact-us",
        component: ContactUs,
    },
    {
        path: "/tournament",
        component: Tournament,
    },
    {
        path: "/mygp-truecaller-subscription-prepare",
        component: MygpTrucallerSubscriptionPrepare,
    },
    {
        path: "/processing",
        component: Processing,
    },
    {
        path: "/truecaller/:reference",
        component: TruecallerCampaigns,
    },
    {
        path: "/:operator/consent",
        component: DobConsentPage,
    },
    {
        path: "/truecaller",
        component: TruecallerLandingPage,
    },
    {
        path: "/truecaller-daily",
        component: TruecallerDaily,
    },
    {
        path: "/truecaller-form",
        component: TrueCallerForm,
    },
    {
        path: "/otp-verify",
        component: TruecallerOTPVerify,
    },
    {
        path: "/about-us",
        component: AboutUs,
    },
    {
        path: "/terms-and-conditions",
        component: TermsAndConditions,
    },
    {
        path: "/refund-policy",
        component: RefundPolicy,
    },
    {
        path: "/privacy-policy",
        component: PrivacyPolicy,
    },
];
