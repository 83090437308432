import { Fragment, useState } from "react";
import Table from "react-bootstrap/Table";
import { toast } from "react-toastify";
import { Modal, ModalBody } from "reactstrap";
import { truecaller_packages } from "../../../constants/truecaller.constants";
import {
    cancelNagadTokenized,
    checkNagadTokenized,
} from "../../../helpers/api.helper";

export default function CheckTokenizedStatus({
    modal,
    toggle,
    setLoading,
    setMsisdn,
    setModal_2,
    package_name,
    reference,
}) {
    const [phone, setPhone] = useState("");
    const [fetching, setFetching] = useState(false);
    const [checkEligibilityRes, setCheckEligibilityRes] = useState(null);

    const [submitted, setSubmitted] = useState(false);

    const handleSubmitForm = async (e) => {
        e.preventDefault();
        // setLoading(true);
        setFetching(true);
        // setMsisdn(phone);

        const pack = truecaller_packages.filter(
            (e) => e?.slug === package_name
        )[0];

        const res = await checkNagadTokenized(phone);

        setCheckEligibilityRes(res);
        setSubmitted(true);
    };

    const cancel = async (e) => {
        e.preventDefault();
        const res = await cancelNagadTokenized(phone);
        if (res?.status) {
            setCheckEligibilityRes(null);
            toast.success("Operation successful!");
        } else {
            toast.error(res?.message || "Sorry! An error occured.");
        }
    };

    return (
        <Modal
            isOpen={modal}
            toggle={toggle}
            centered
            className='truecaller-modal'
        >
            <ModalBody className=' text-center p-4 pb-2'>
                {submitted ? (
                    checkEligibilityRes?.status ? (
                        <Fragment>
                            <h5>Nagad Token Eligibility</h5>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Nagad Account</th>
                                        <th>Eligibility</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ color: "green" }}>
                                            {
                                                checkEligibilityRes?.nagad_account_no
                                            }
                                        </td>
                                        <td
                                            style={{
                                                color: checkEligibilityRes?.eligible
                                                    ? "#01437e"
                                                    : "auto",
                                            }}
                                        >
                                            {checkEligibilityRes?.eligible
                                                ? "true"
                                                : "false"}
                                        </td>
                                        <td
                                            style={{ cursor: "pointer" }}
                                            title='Remove'
                                            onClick={cancel}
                                        >
                                            <span>
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    height='16'
                                                    width='14'
                                                    viewBox='0 0 448 512'
                                                >
                                                    <path
                                                        fill='#e8455d'
                                                        d='M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.7 23.7 0 0 0 -21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0 -16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z'
                                                    />
                                                </svg>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <h5>Nagad Token Eligibility</h5>
                            <span>No data found for tokenized payment!</span>
                        </Fragment>
                    )
                ) : (
                    <div className='number-box d-block'>
                        <img
                            className='m-screen'
                            src='/assets/images/truecaller-logo-1.png'
                            alt=''
                        />
                        <div className='unsubscribe-body w-100 mt-3'>
                            <h2 className='text-black'>
                                Nagad Tokenized Payment Status
                            </h2>
                            <form action='#' className='caller-form w-100'>
                                <label
                                    for=''
                                    className='form-label text-start d-block'
                                >
                                    Mobile Number
                                </label>
                                <div className='input-group mb-3'>
                                    <span className='input-group-text bg-white'>
                                        +88
                                    </span>
                                    <input
                                        type='number'
                                        className='form-control py-2 placeholder-color'
                                        placeholder='01X XXXX XXXX'
                                        value={phone}
                                        onChange={(e) =>
                                            setPhone(e.target.value)
                                        }
                                    />
                                </div>
                                <span></span>
                                <button
                                    className='btn btn-blue w-100'
                                    disabled={phone?.length !== 11 || fetching}
                                    onClick={handleSubmitForm}
                                >
                                    {`${fetching ? "Processing" : "Next"}`}
                                </button>
                            </form>
                        </div>
                    </div>
                )}
            </ModalBody>
        </Modal>
    );
}
