import { useEffect } from "react";
import Footer from "../components/layout/footer/footer";
import Header from "../components/layout/header/header";

export default function RefundPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);

        // loading bootstrap plugin
        const script2 = document.createElement("script");
        script2.src = "/assets/js/bootstrap.bundle.min.js";
        script2.async = true;
        document.body.appendChild(script2);

        // loading slick plugin
        const script3 = document.createElement("script");
        script3.src = "/assets/vendor/slick/slick.min.js";
        script3.async = true;
        document.body.appendChild(script3);

        //loading aos plugin
        const script4 = document.createElement("script");
        script4.src = "/assets/vendor/aos/aos.js";
        script4.async = true;
        document.body.appendChild(script4);

        // loading main js file
        const script1 = document.createElement("script");
        script1.src = "/assets/js/main.js";
        script1.async = true;
        document.body.appendChild(script1);

        return () => {
            document.body.removeChild(script1);
            document.body.removeChild(script2);
            document.body.removeChild(script3);
            document.body.removeChild(script4);
        };
    }, []);
    return (
        <div className='main-wrapper'>
            <Header />
            <main className='main-area'>
                <section className=''>
                    <div className='container'>
                        <div className='contact py-5'>
                            <div className='text-center'>
                                <strong>Refund Policy</strong>
                            </div>
                            <div>
                                <p>
                                    If there is any valid claim of refund by any
                                    registered user, Momagic will arrange it
                                    with no further delay within 7 to 10 working
                                    days. Refund claims will be judged as per
                                    the governing law of Bangladesh and the
                                    consumer rights law of Bangladesh. For any
                                    complain, user may
                                    <b> call: 09611116303</b> Or send an email
                                    with details at
                                    <b> supports@momagicbd.com</b>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}
