import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { prepareConsentAPI } from "../../../helpers/api.helper";
import { isValidGPMSISDN } from "../../../helpers/helper";
import "./style.css";

export default function TruecallerDaily() {
    const history = useHistory();
    const [msisdn, setMsisdn] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isValidGPMSISDN("880" + msisdn.slice(-10))) {
            toast.error("Please, enter a valid grameenphone msisdn");
            return;
        }
        setLoading(true);

        const res_consent = await prepareConsentAPI(
            12,
            8,
            37,
            null,
            "880" + msisdn.slice(-10),
            {},
            "truecaller-daily"
        );
        if (res_consent.redirect && res_consent.success) {
            window.location.href = res_consent.charge_redirect_url;
        } else if (res_consent?.redirect_url) {
            history.push(res_consent?.redirect_url);
        } else {
            toast.error(res_consent?.message || "Sorry! Something went wrong!");
            setLoading(false);
        }
    };

    return (
        <div className='truecaller-daily'>
            <div className='container main-wrapper'>
                <div className='wrapper-inner text-center'>
                    <h2 className='heading'>Tired of spam calls?</h2>
                    <div className='banner'>
                        <img
                            src='/assets/images/Truecaller/daily/CharacterDesign.png'
                            alt='truecaller-banner'
                        />
                    </div>
                    <div className='content'>
                        <p className='sub-heading'>
                            Let's get rid of this today!
                        </p>

                        <p className='package'>
                            Enter your grameenphone number now!
                        </p>
                        <form className='form-inner'>
                            <div className='number-form'>
                                <div className='input-group'>
                                    <div className='input-group-prepend'>
                                        <span
                                            className='input-group-text'
                                            id='inputGroupPrepend'
                                        >
                                            +88
                                        </span>
                                    </div>
                                    <input
                                        type='number'
                                        placeholder='017XXXXXXXX'
                                        className='form-control input-field'
                                        onChange={(e) =>
                                            setMsisdn(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <button
                                type='submit'
                                className='continue-btn grow-shrink'
                                onClick={handleSubmit}
                                disabled={loading}
                            >
                                {loading ? "Loading" : "Continue"}
                            </button>

                            <div className='powered-by'>
                                Powered by
                                <img
                                    src='/assets/images/truecaller-logo-1.png'
                                    alt='truecaller'
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
