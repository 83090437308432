import { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import TrueCallerFormModal from "../../../components/modals/truecaller/truecaller.form";
import TruecallerOTP from "../../../components/modals/truecaller/truecaller.otp";
import FullPageLoader from "../../../components/partials/loader/full-page";
import { truecaller_packages } from "../../../constants/truecaller.constants";
import { prepareConsentAPI } from "../../../helpers/api.helper";

export default function NotPurchased({ msisdn, refetch, ref_1, ref_2 }) {
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [modal_2, setModal_2] = useState(false);
    const [phone, setPhone] = useState("");
    const [packName, setPackName] = useState(null);

    const toggle = () => {
        setModal(!modal);
        setLoading(false);
    };
    const toggle_2 = () => {
        setModal_2(!modal_2);
        setLoading(false);
    };

    const handleClick = async (pckg) => {
        // setLoading(true);
        setPackName(pckg);

        if (msisdn) {
            setLoading(true);
            const res_consent = await prepareConsentAPI(
                12,
                8,
                pckg === "monthly" ? 39 : pckg === "weekly" ? 38 : 37,
                null,
                msisdn,
                {},
                ref_1
            );
            if (res_consent.redirect && res_consent.success) {
                window.location.href = res_consent.charge_redirect_url;
            } else if (res_consent?.redirect_url) {
                history.push(res_consent?.redirect_url);
            } else {
                toast.error(
                    res_consent?.message || "Sorry! Somethind went wrong!"
                );
            }
            setLoading(false);
        } else {
            setModal(true);
            // history.push(`/truecaller-form?package=${pckg === 'monthly' ? 'monthly' : pckg === 'weekly' ? 'weekly' : 'daily'}&reference=${mygp_without_reference}${msisdn ? '&msisdn=' + msisdn : ''}`)
        }
    };

    return (
        <Fragment>
            <div
                className='screen'
                style={{
                    backgroundImage:
                        ref_1 === "nagad"
                            ? "url(/assets/images/TruecallerBG.png)"
                            : "auto",
                }}
            >
                <div className='screen-row'>
                    <div className='header'>
                        <h2>Trust your communication with</h2>
                        <img
                            src='/assets/images/TruecallerPremium.png'
                            alt=''
                        />
                    </div>
                    <div className='body' style={{ paddingTop: "10px" }}>
                        <img
                            src='/assets/images/TrueCaller_Feature.png'
                            alt=''
                        />
                    </div>
                    <div className='footer'>
                        <ul>
                            {truecaller_packages.map((item, indx) => (
                                <li key={indx}>
                                    <div
                                        onClick={() => handleClick(item?.slug)}
                                    >
                                        <img
                                            src={item?.src}
                                            alt={item?.title}
                                        />
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <p>* 15% VAT + 1% SC Applicable</p>
                    </div>
                </div>
            </div>
            {loading ? <FullPageLoader /> : ""}
            {modal && (
                <TrueCallerFormModal
                    modal={modal}
                    toggle={toggle}
                    setLoading={setLoading}
                    setMsisdn={setPhone}
                    setModal_2={setModal_2}
                    package_name={packName}
                    reference={ref_2}
                />
            )}
            {modal_2 && (
                <TruecallerOTP
                    modal={modal_2}
                    toggle={toggle_2}
                    setLoading={setLoading}
                    msisdn={phone}
                    package_name={packName}
                    refetch={refetch}
                />
            )}
        </Fragment>
    );
}
