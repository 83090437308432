import { Link } from "react-router-dom";
export default function Footer() {
    return (
        <footer className='footer-area'>
            <div className='footer-top-area bg-light pb-5 pt-2'>
                <div className='container'>
                    <div className='footer-top'>
                        <div
                            className='s-head text-center py-4'
                            data-aos='zoom-in-up'
                            data-aos-duration={1300}
                        >
                            <img
                                src='assets/images/footer-logo.png'
                                alt='footer-logo'
                            />
                            <img src='assets/images/SSL Commerz.jpg' />
                        </div>
                        <div className='footer-links' data-aos='fade-up'>
                            <div className='row'>
                                <div className='col-lg-3 mb-5 mb-lg-0'>
                                    <div className='row'>
                                        <div className='col subscription '>
                                            <div className='f-head'>
                                                <h4>Get Notifications</h4>
                                            </div>
                                            <form action='#'>
                                                <div className='input-group mb-3'>
                                                    <input
                                                        type='email'
                                                        className='form-control bg-dark border-0 text-white'
                                                        placeholder='Your Email'
                                                        aria-label="Recipient's username"
                                                        aria-describedby='button-addon2'
                                                    />
                                                    <button
                                                        className='btn btn-dark'
                                                        type='button'
                                                        id='button-addon2'
                                                    >
                                                        <i className='fa fa-arrow-right' />
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                        <div className='col f-lang d-block d-lg-none'>
                                            <div className='f-head'>
                                                <h4>Language</h4>
                                            </div>
                                            <select className='form-select bg-dark text-white border-0'>
                                                <option defaultValue='eng'>
                                                    English
                                                </option>
                                                <option value='bn'>
                                                    Bangla
                                                </option>
                                                <option value='in'>
                                                    Hindi
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='row flinks'>
                                        <div className='col'>
                                            <div className='f-head'>
                                                <h4>Categories</h4>
                                            </div>
                                            <ul className='list-group list-group-flush'>
                                                <li className='list-group-item'>
                                                    <a href='#'>Games</a>
                                                </li>
                                                <li className='list-group-item'>
                                                    <a href='#'>Streaming</a>
                                                </li>
                                                <li className='list-group-item'>
                                                    <a href='#'>Learning</a>
                                                </li>
                                                <li className='list-group-item'>
                                                    <a href='#'>Software</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='col'>
                                            <div className='f-head'>
                                                <h4>Hullor</h4>
                                            </div>
                                            <ul className='list-group list-group-flush'>
                                                <li className='list-group-item'>
                                                    <Link to='/about-us'>
                                                        About Us
                                                    </Link>
                                                </li>
                                                <li className='list-group-item'>
                                                    <Link to='terms-and-conditions'>
                                                        Terms & Conditions
                                                    </Link>
                                                </li>
                                                <li className='list-group-item'>
                                                    <Link to='privacy-policy'>
                                                        Privacy Policy
                                                    </Link>
                                                </li>
                                                <li className='list-group-item'>
                                                    <Link to='refund-policy'>
                                                        Refund Policy
                                                    </Link>
                                                </li>
                                                <li className='list-group-item'>
                                                    <Link to='/contact-us'>
                                                        Contact Us
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='col'>
                                            <div className='f-head'>
                                                <h4>Contacts</h4>
                                            </div>
                                            <ul className='list-group list-group-flush'>
                                                <li className='list-group-item'>
                                                    <a href='#'>Call Now</a>
                                                </li>
                                                <li className='list-group-item'>
                                                    <a href='#'>
                                                        Send an email
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3 d-none d-lg-block'>
                                    <div className='f-lang'>
                                        <div className='f-head'>
                                            <h4>Language</h4>
                                        </div>
                                        <select className='form-select bg-dark text-white border-0'>
                                            <option defaultValue='eng'>
                                                English
                                            </option>
                                            <option value='bn'>Bangla</option>
                                            <option value='in'>Hindi</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* END FOOTER TOP */}
            <div className='footer-copyright bg-dark py-4 text-center text-white'>
                <p className='m-0'>
                    &copy;{" "}
                    {`Copyright ${new Date().getFullYear()} All Rights Reserved.`}
                </p>
            </div>
        </footer>
    );
}
