import axios from "axios";
import {
    ACQUIRE_CHARGE_URL_NAGAD,
    BKASH_RECURRING_REDIRECT,
    BKASH_UNSUBSCRIBE,
    CHECKOUT_URL,
    CONSENT_CHARGING_API,
    CONTACT_FORM_SUBMIT_URL,
    GET_ACCESS_TOKEN,
    GP_OTP_CHARGING_URL,
    HOME_PAGE_CONTENT,
    LAST_ORDER_URL,
    LOGIN_URL,
    MYGP_CUSTOMER_INFO,
    NAGAD_ELIGIBILITY_CANCEL,
    NAGAD_ELIGIBILITY_CHECK,
    ORDER_STATUS_UPDATE,
    OTP_VERIFY_URL,
    PREPARE_CONSENT_API,
    ROBI_UNSUBSCRIBE,
    SHOW_CATEGORY_WISE_PRODUCT_URL,
    TOGGLE_AUTO_RENEW,
    TRUECALLER_UNSUBSCRIBE,
    USER_PURCHASE_HISTORY_URL,
    VERIFY_TRUECALLER_SUBSCRIPTION,
} from "../constants/api.constants";

export const getAccessToken = async () => {
    try {
        const { data: response } = await axios({
            method: "POST",
            url: GET_ACCESS_TOKEN,
            data: {
                lang: "en",
                source: "web",
                version: "2.0.0",
                store_id: "1b4a19e331aa653945ec0cf8b86435c9",
            },
        });
        return response;
    } catch (err) {
        console.log(err.message);
        return { error: err.message };
    }
};

export const fetchData = async () => {
    try {
        const { data: response } = await axios({
            method: "POST",
            url: HOME_PAGE_CONTENT,
            data: {
                lang: "en",
                source: "web",
                version: "2.0.0",
            },
        });
        return response;
    } catch (err) {
        console.log(err.message);
        return { error: err.message };
    }
};

export const getPopupData = async (category_id) => {
    console.log("category wise data: ", category_id);
    try {
        const { data: response } = await axios({
            method: "POST",
            url: SHOW_CATEGORY_WISE_PRODUCT_URL,
            data: {
                lang: "en",
                source: "web",
                version: "2.0.0",
                category_id: category_id,
            },
        });
        return response;
    } catch (err) {
        console.log(err.message);
        return { error: err.message };
    }
};

export const orderSubmitAPI = async (
    category_id,
    channel_id,
    package_id,
    email,
    msisdn,
    additionalData = {}
) => {
    try {
        const { data: response } = await axios({
            method: "POST",
            url: CHECKOUT_URL,
            data: {
                lang: "en",
                source: "web",
                version: "2.0.0",
                category_id: category_id,
                channel_id: channel_id,
                package_id: package_id,
                email: email,
                msisdn: msisdn,
                additional_data: additionalData,
            },
        });
        return response;
    } catch (err) {
        console.log(err.message);
        return { error: err.message };
    }
};

export const prepareConsentAPI = async (
    category_id,
    channel_id,
    package_id,
    email,
    msisdn,
    additionalData = {},
    reference
) => {
    try {
        const { data: response } = await axios({
            method: "POST",
            url: PREPARE_CONSENT_API,
            data: {
                lang: "en",
                source: "web",
                version: "2.0.0",
                category_id: category_id,
                channel_id: channel_id,
                package_id: package_id,
                email: email,
                msisdn: msisdn,
                additional_data: additionalData,
                reference,
            },
        });
        return response;
    } catch (err) {
        console.log(err.message);
        return { error: err.message };
    }
};

export const truecallerPayment = async (
    category_id,
    payment_channel_id,
    product_id,
    email,
    msisdn,
    additional_data,
    reference
) => {
    const response =
        payment_channel_id === 8
            ? await prepareConsentAPI(
                  category_id,
                  payment_channel_id,
                  product_id,
                  email,
                  msisdn,
                  additional_data,
                  reference
              )
            : payment_channel_id === 1
            ? await acquireChargeUrlBkashRecurring({
                  msisdn,
                  payment_channel_id,
                  product_id,
              })
            : await acquireChargeUrlNagad({
                  msisdn,
                  payment_channel_id,
                  product_id,
              });

    return {
        ...response,
        success: response?.success || response?.status || false,
        redirect: response?.success || response?.status || false,
        charge_redirect_url:
            response?.charge_redirect_url ||
            response?.url ||
            response.redirect_url,
    };
};

export const acquireChargeUrlBkashRecurring = async (data) => {
    try {
        const { data: response } = await axios.post(BKASH_RECURRING_REDIRECT, {
            lang: "en",
            source: "web",
            version: "2.0.0",
            ...data,
        });
        return response;
    } catch (err) {
        console.log(err.message);
        return { message: err.message };
    }
};

export const acquireChargeUrlNagad = async (data) => {
    try {
        const { data: response } = await axios.post(ACQUIRE_CHARGE_URL_NAGAD, {
            lang: "en",
            source: "web",
            version: "2.0.0",
            ...data,
        });
        return response;
    } catch (err) {
        console.log(err.message);
        return { message: err.message };
    }
};
export const acquireChargeUrlSSL = async (data) => {
    try {
        const { data: response } = await axios.post(ACQUIRE_CHARGE_URL_NAGAD, {
            lang: "en",
            source: "web",
            version: "2.0.0",
            ...data,
        });
        return response;
    } catch (err) {
        console.log(err.message);
        return { message: err.message };
    }
};

export const checkNagadTokenized = async (msisdn) => {
    try {
        const { data: response } = await axios.post(NAGAD_ELIGIBILITY_CHECK, {
            lang: "en",
            source: "web",
            version: "2.0.0",
            msisdn,
        });
        return response;
    } catch (err) {
        console.log(err.message);
        return { message: err.message };
    }
};
export const cancelNagadTokenized = async (msisdn) => {
    try {
        const { data: response } = await axios.post(NAGAD_ELIGIBILITY_CANCEL, {
            lang: "en",
            source: "web",
            version: "2.0.0",
            msisdn,
        });
        return response;
    } catch (err) {
        console.log(err.message);
        return { message: err.message };
    }
};

export const getLastOrders = async (msisdn) => {
    try {
        const { data: response } = await axios({
            method: "POST",
            url: LAST_ORDER_URL,
            data: {
                lang: "en",
                source: "web",
                version: "2.0.0",
                msisdn,
            },
        });
        return response;
    } catch (err) {
        console.log(err.message);
        return { error: err.message };
    }
};

export const updateOrderStatus = async (order_id, status) => {
    try {
        const { data: response } = await axios({
            method: "POST",
            url: `${ORDER_STATUS_UPDATE}/${order_id}/${status}`,
            data: {
                lang: "en",
                source: "web",
                version: "2.0.0",
            },
        });
        return response;
    } catch (err) {
        console.log(err.message);
        return { error: err.message };
    }
};

export const updateOrderHistoryAPI = async (
    order_id,
    customer_reference,
    consent_id
) => {
    try {
        const { data: response } = await axios({
            method: "POST",
            url: CONSENT_CHARGING_API,
            data: {
                lang: "en",
                source: "web",
                version: "2.0.0",
                order_id,
                customer_reference,
                consent_id,
            },
        });
        return response;
    } catch (err) {
        console.log(err.message);
        return { error: err.message };
    }
};

export const otpSubmitAPI = async (order_id, phone, email, otp) => {
    try {
        const { data: response } = await axios({
            method: "POST",
            url: GP_OTP_CHARGING_URL,
            data: {
                lang: "en",
                source: "web",
                version: "2.0.0",
                otp: otp,
                order_id: order_id,
                email: email,
                msisdn: phone,
            },
        });
        return response;
    } catch (err) {
        console.log(err.message);
        return { error: err.message };
    }
};

export const getPurchaseHistory = async (phone = "") => {
    try {
        const { data: response } = await axios({
            method: "POST",
            url: USER_PURCHASE_HISTORY_URL,
            data: {
                lang: "en",
                source: "web",
                version: "2.0.0",
                msisdn: phone,
            },
        });
        return response;
    } catch (err) {
        console.log(err.message);
        return { error: err.message };
    }
};

export const toggleAutoRenew = async (order_id, msisdn, auto_renew) => {
    try {
        const { data: response } = await axios({
            method: "POST",
            url: TOGGLE_AUTO_RENEW,
            data: {
                lang: "en",
                source: "web",
                version: "2.0.0",
                order_id,
                msisdn,
                auto_renew,
            },
        });
        return response;
    } catch (err) {
        console.log(err.message);
        return { error: err.message };
    }
};

export const cancelBkashSubscription = async (msisdn, product_id) => {
    try {
        const { data: response } = await axios({
            method: "POST",
            url: BKASH_UNSUBSCRIBE,
            data: {
                lang: "en",
                source: "web",
                version: "2.0.0",
                msisdn,
                product_id,
            },
        });
        return response;
    } catch (err) {
        console.log(err.message);
        return { error: err.message };
    }
};

export const cancelRobiSubscription = async (order_id) => {
    try {
        const { data: response } = await axios({
            method: "GET",
            url: `${ROBI_UNSUBSCRIBE}/${order_id}`,
            data: {
                lang: "en",
                source: "web",
                version: "2.0.0",
            },
        });
        return response;
    } catch (err) {
        return { error: err?.response?.data?.message || err.message };
    }
};

export const unsubscribeTruecaller = async (msisdn, package_id) => {
    try {
        const { data: response } = await axios({
            method: "POST",
            url: TRUECALLER_UNSUBSCRIBE,
            data: {
                lang: "en",
                source: "web",
                version: "2.0.0",
                msisdn,
                package_id,
            },
        });
        return response;
    } catch (err) {
        console.log(err.message);
        return { error: err.message };
    }
};

export const submitContactForm = async (data = {}) => {
    try {
        const { data: response } = await axios({
            method: "POST",
            url: CONTACT_FORM_SUBMIT_URL,
            data: {
                lang: "en",
                source: "web",
                version: "2.0.0",
                user_name: data.name.value,
                msisdn: data.phone.value,
                email: data.email.value,
                message: data.message.value,
            },
        });
        return response;
    } catch (err) {
        console.log(err.message);
        return { error: err.message };
    }
};

export const callLogInAPI = async (phone) => {
    try {
        const { data: response } = await axios({
            method: "POST",
            url: LOGIN_URL,
            data: {
                lang: "en",
                source: "web",
                version: "2.0.0",
                msisdn: phone,
            },
        });
        return response;
    } catch (err) {
        console.log(err.message);
        return { error: err.message };
    }
};

export const verifyOTP = async (phone, otp) => {
    try {
        const { data: response } = await axios({
            method: "POST",
            url: OTP_VERIFY_URL,
            data: {
                lang: "en",
                source: "web",
                version: "2.0.0",
                msisdn: phone,
                otp: otp,
            },
        });
        return response;
    } catch (err) {
        console.log(err.message);
        return { error: err.message };
    }
};

export const verifyTruecallerSubscription = async (
    phone,
    otp,
    package_name
) => {
    try {
        const { data: response } = await axios({
            method: "POST",
            url: VERIFY_TRUECALLER_SUBSCRIPTION,
            data: {
                lang: "en",
                source: "web",
                version: "2.0.0",
                msisdn: phone,
                otp: otp,
                package_name,
            },
        });
        return response;
    } catch (err) {
        console.log(err.message);
        return { error: err.message };
    }
};

export const getCustomerInfoForMygp = async (token) => {
    try {
        const { data: response } = await axios({
            method: "POST",
            url: MYGP_CUSTOMER_INFO,
            data: {
                lang: "en",
                source: "web",
                version: "2.0.0",
                token,
            },
        });
        return response;
    } catch (err) {
        console.log(err.message);
        return { error: err.message };
    }
};
