import { Fragment, useState } from "react";
import FullPageLoader from "../../../components/partials/loader/full-page";
import { truecaller_app_link } from "../../../constants/truecaller.constants";


export default function Purchased({ hasUnsubscribed, validTill, handleUnsubscribe }) {
    const [loading, setLoading] = useState(false);

    return (
        <Fragment>
            <div className="screen-white">
                <div className="screen-row screen-unsubscribe pt-2">
                    <img className="m-screen" src="assets/images/Mobile-2.png" alt="" />
                    <div className="unsubscribe-body">
                        <div>
                            <p>You Already Have Purchased </p>
                            <img src="assets/images/Truecaller-blue.png" alt="" />
                        </div>
                        <p className="uctime "><span className="fw-bold text-danger">Valid Till</span>: {`${validTill}`} </p>
                        {hasUnsubscribed ? (
                            <button className="btn btn-blue" disabled>Unsubscribed</button>
                        ) : (
                            <button className="btn btn-blue" onClick={handleUnsubscribe}>Unsubscribe</button>
                        )}
                        <button
                            className="btn btn-blue-outline"
                            onClick={(e) => {
                                e.preventDefault();
                                setLoading(true);
                                window.location.href = truecaller_app_link;
                                setTimeout(() => {
                                    setLoading(false);
                                }, 5000);
                            }}
                        >
                            Go to App
                        </button>
                        <p className="uctime"><strong>N.B:</strong> Re-subscription available after expiration until the specified time</p>

                    </div>
                </div>
            </div>
            {loading ? <FullPageLoader /> : ""}
        </Fragment>
    );
};